import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { ProviderPlanAbbreviation } from "@/types/api/provider";
import {
    GET_SERVER_PLAN_NAME_LIST,
    GET_STORAGE_PLAN_NAME_LIST,
} from "@/configs/ApiConfig";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { authStore } from "../AuthStore";

@Module({
    name: "providerPlanName",
    dynamic: true,
    store,
    namespaced: true,
})
class ProviderPlanNameStore extends VuexModule {
    private _serverPlanList: ProviderPlanAbbreviation[] = [];
    private _storagePlanList: ProviderPlanAbbreviation[] = [];

    private _hasError = false;
    private _errorMessage = "";

    public get serverPlanList(): ProviderPlanAbbreviation[] {
        return this._serverPlanList;
    }

    public get storagePlanList(): ProviderPlanAbbreviation[] {
        return this._storagePlanList;
    }

    public get hasError(): boolean {
        return this._hasError;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    @Mutation
    private setServerPlanList(data: ProviderPlanAbbreviation[]): void {
        this._serverPlanList = data;
    }

    @Mutation
    private setStoragePlanList(data: ProviderPlanAbbreviation[]): void {
        this._storagePlanList = data;
    }

    @Mutation
    private setHasError(status: boolean): void {
        this._hasError = status;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Action({ rawError: true })
    public initError(): void {
        this.setHasError(false);
        this.setErrorMessage("");
    }

    @Action({ rawError: true })
    public async getServerPlanNameList(): Promise<void> {
        const response = await axios
            .get(GET_SERVER_PLAN_NAME_LIST, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setHasError(true);
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setHasError(false);
        this.setErrorMessage("");
        this.setServerPlanList(result.data);
    }

    @Action({ rawError: true })
    public async getStoragePlanNameList(): Promise<void> {
        const response = await axios
            .get(GET_STORAGE_PLAN_NAME_LIST, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setHasError(true);
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setHasError(false);
        this.setErrorMessage("");
        this.setStoragePlanList(result.data);
    }
}

export const providerPlanNameStore = getModule(ProviderPlanNameStore);
