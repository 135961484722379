import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { ServiceAccountInfo } from "@/types/api/service-account";
import {
    DELETE_SERVICE_ACCOUNT,
    GET_SERVICE_ACCOUNT,
    GET_SERVICE_ACCOUNT_LIST,
    POST_SERVICE_ACCOUNT_REGISTER,
    POST_SERVICE_ACCOUNT_UPDATE,
} from "@/configs/ApiConfig";
import { authStore } from "../AuthStore";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import {
    ServiceAccountForm,
    ServiceAccountIdParams,
} from "@/types/modules/service-account";

@Module({
    name: "serviceAccount",
    dynamic: true,
    store,
    namespaced: true,
})
class ServiceAccountStore extends VuexModule {
    private _serviceAccountList: ServiceAccountInfo[] = [];

    private _serviceAccount!: ServiceAccountInfo;

    private _errorMessage = "";
    private _deleteErrorMessage = "";

    private _postStatus = false;
    private _deleteStatus = false;

    public get serviceAccountList(): ServiceAccountInfo[] {
        return this._serviceAccountList;
    }

    public get serviceAccount(): ServiceAccountInfo {
        return this._serviceAccount;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    public get deleteErrorMessage(): string {
        return this._deleteErrorMessage;
    }

    public get postStatus(): boolean {
        return this._postStatus;
    }

    public get deleteStatus(): boolean {
        return this._deleteStatus;
    }

    @Mutation
    private setServiceAccountList(data: ServiceAccountInfo[]): void {
        this._serviceAccountList = data;
    }

    @Mutation
    private setServiceAccount(data: ServiceAccountInfo): void {
        this._serviceAccount = data;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Mutation
    private setDeleteErrorMessage(message: string): void {
        this._deleteErrorMessage = message;
    }

    @Mutation
    private setPostStatus(status: boolean): void {
        this._postStatus = status;
    }

    @Mutation
    private setDeleteStatus(status: boolean): void {
        this._deleteStatus = status;
    }

    @Action({ rawError: true })
    public initStore(): void {
        this.setPostStatus(false);
        this.setDeleteStatus(false);
        this.setErrorMessage("");
        this.setDeleteErrorMessage("");
    }

    @Action({ rawError: true })
    public async getServiceAccountList(): Promise<void> {
        const response = await axios
            .get(GET_SERVICE_ACCOUNT_LIST, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setErrorMessage("");
        this.setServiceAccountList(result.data);
    }

    @Action({ rawError: true })
    public async getServiceAccount(
        params: ServiceAccountIdParams
    ): Promise<void> {
        const response = await axios
            .get(
                `${GET_SERVICE_ACCOUNT}/${params.serviceId}/${params.accountId}`,
                {
                    headers: {
                        Authorization: `Bearer ${authStore.token}`,
                    },
                }
            )
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setErrorMessage("");
        this.setServiceAccount(result.data);
    }

    @Action({ rawError: true })
    public async postRegisterServiceAccount(
        request: ServiceAccountForm
    ): Promise<void> {
        const response = await axios
            .post(POST_SERVICE_ACCOUNT_REGISTER, request, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.message);
            return;
        }
        this.setErrorMessage("");
        this.setPostStatus(true);
    }

    @Action({ rawError: true })
    public async postUpdateServiceAccount(
        params: ServiceAccountIdParams
    ): Promise<void> {
        const response = await axios
            .post(
                `${POST_SERVICE_ACCOUNT_UPDATE}/${params.serviceId}/${params.accountId}`,
                params.serviceAccountForm,
                {
                    headers: {
                        Authorization: `Bearer ${authStore.token}`,
                    },
                }
            )
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setErrorMessage("");
        this.setPostStatus(true);
    }

    @Action({ rawError: true })
    public async deleteServiceAccount(
        params: ServiceAccountIdParams
    ): Promise<void> {
        const response = await axios
            .delete(
                `${DELETE_SERVICE_ACCOUNT}/${params.serviceId}/${params.accountId}`,
                {
                    headers: {
                        Authorization: `Bearer ${authStore.token}`,
                    },
                }
            )
            .catch((error) => error.response || error);
        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            this.setDeleteStatus(false);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setDeleteErrorMessage(result.data.message);
            return;
        }
        this.setDeleteErrorMessage("");
        this.setDeleteStatus(true);
    }
}

export const serviceAccountStore = getModule(ServiceAccountStore);
