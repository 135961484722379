var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('page-back-button'),_c('div',{staticClass:"page-container"},[_c('h1',[_vm._v("サーバ一覧")]),_c('div',[_c('div',{staticClass:"button-container"},[_c('v-btn',{attrs:{"link":"","to":"/server/base/operation","color":"primary","small":""}},[_vm._v("新規サーバーを追加 ")])],1),_c('div',{staticClass:"list-container"},[_c('div',{staticClass:"search-line"},[_c('v-select',{staticClass:"search-box",attrs:{"label":"表示リスト","dense":"","items":_vm.tableTypeList},model:{value:(_vm.listType),callback:function ($$v) {_vm.listType=$$v},expression:"listType"}}),_c('v-text-field',{staticClass:"search-box",attrs:{"label":"システム名","append-icon":"mdi-magnify","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"search-box",attrs:{"label":"契約状況","dense":"","items":_vm.contractStatusList},model:{value:(_vm.contractStatus),callback:function ($$v) {_vm.contractStatus=$$v},expression:"contractStatus"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.displayServerList,"search":_vm.search,"items-per-page":1000,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.largeCategory",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.largeCategory.categoryId) + "." + (item.largeCategory.categoryName)))+" ")]}},{key:"item.smallCategory",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.largeCategory.categoryId) + "." + (item.largeCategory.categoryName)))+" ")]}},{key:"item.bastionHost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayBastionHost(item.bastionHost))+" ")]}},{key:"item.domain",fn:function(ref){
var item = ref.item;
return _vm._l((item.domain),function(list){return _c('ul',{key:list,staticClass:"domain-list"},[_c('li',[_vm._v(_vm._s(list))])])})}},{key:"item.ssh[0].sshUser",fn:function(ref){
var item = ref.item;
return [(item.ssh[0])?_c('span',[_vm._v(" "+_vm._s(item.ssh[0].sshUser)+" "),(item.ssh[0])?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.writeToClipboard(item.ssh[0].sshUser)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-clipboard-outline")])],1):_vm._e()],1):_vm._e()]}},{key:"item.ssh[0].sshPassword",fn:function(ref){
var item = ref.item;
return [(item.ssh[0])?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.writeToClipboard(item.ssh[0].sshPassword)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-clipboard-outline")])],1):_vm._e()]}},{key:"item.serverPlan.baseFee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceFormat")(item.serverPlan.baseFee))+" ")]}},{key:"item.storagePlan.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceFormat")(item.storagePlan.fee))+" ")]}},{key:"item.totalExtraStoragePlanFee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceFormat")(item.totalExtraStoragePlanFee))+" ")]}},{key:"item.serverPlan.ipAddressFee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceFormat")(item.serverPlan.ipAddressFee))+" ")]}},{key:"item.networkFee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceFormat")(item.networkFee))+" ")]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceFormat")(item.totalAmount))+" ")]}},(_vm.contractStatus === '契約中')?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editServerInfo(item.serverId)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"secondary","icon":""},on:{"click":function($event){return _vm.openDeleteDialog(item.serverId, item.ssh[0])}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}:null],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("削除確認 ")]),_c('v-card-text',[(_vm.deleteErrorMessage)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.deleteErrorMessage)+" ")]):_vm._e(),_vm._v(" データを削除してよろしいですか？")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.closeDeleteDialog}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.deleteServerInfo}},[_vm._v("削除")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }