import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { SystemForm } from "@/types/modules/server";
import { GET_SYSTEM_LIST, POST_SYSTEM_REGISTER } from "@/configs/ApiConfig";
import { authStore } from "./AuthStore";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { SystemInfo } from "@/types/api/system";
import { belongsPermissionGroupStore } from "./Employees/BelongsPermissionGroupStore";

@Module({
    name: "server",
    dynamic: true,
    store,
    namespaced: true,
})
class SystemStore extends VuexModule {
    private _systemList: SystemInfo[] = [];

    private _errorMessage = "";

    public get systemList(): SystemInfo[] {
        return this._systemList;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    @Mutation
    private setSystemList(data: SystemInfo[]): void {
        const filterSystemList = data.filter((system) => {
            let matchFlag = false;
            belongsPermissionGroupStore.canAddGroupList.forEach((addGroup) => {
                matchFlag = addGroup.systemName === system.systemName;
            });
            if (authStore.employeeType === "管理ユーザー" || matchFlag) {
                return system;
            }
        });
        this._systemList = filterSystemList;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Action({ rawError: true })
    public initError(): void {
        this.setErrorMessage("");
    }

    @Action({ rawError: true })
    public async getSystemList(): Promise<void> {
        const response = await axios
            .get(GET_SYSTEM_LIST, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setErrorMessage("");
        this.setSystemList(result.data);
    }

    @Action({ rawError: true })
    public async postRegisterSystem(request: SystemForm): Promise<boolean> {
        const response = await axios
            .post(POST_SYSTEM_REGISTER, request, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return false;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return false;
        }

        const result = response.data;

        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return false;
        }
        this.setErrorMessage("");
        return true;
    }
}

export const systemStore = getModule(SystemStore);
