var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('page-back-button'),_c('div',{staticClass:"page-container"},[_c('h1',[_vm._v("サーバ操作権限グループ一覧")]),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},scopedSlots:_vm._u([(_vm.isAdminEmployee)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"button-container"},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_vm._v("新規グループを追加")])],1)]}}:null],null,true),model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('add-group-dialog',{on:{"input":_vm.closeAddDialog}})],1),_c('div',{staticClass:"list-container"},[_c('div',{staticClass:"search-container"},[_c('v-select',{attrs:{"label":"システム名","name":"system-name","dense":"","items":_vm.systemList},on:{"change":function($event){return _vm.filterGroupBySystemName()}},model:{value:(_vm.systemName),callback:function ($$v) {_vm.systemName=$$v},expression:"systemName"}})],1),(_vm.errorMessage)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"group-list-container"},[_c('v-row',_vm._l((_vm.showGroupList),function(group,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-card',{attrs:{"height":"300px"}},[_c('v-card-title',{staticClass:"white--text primary"},[_vm._v(" "+_vm._s(group.groupName)+" ")]),_c('v-card-text',{staticClass:"card-content"},[_c('dl',[_c('dt',[_vm._v("システム：")]),_c('dd',[_vm._v(" "+_vm._s(group.systemName)+" ")]),_c('dt',[_vm._v("区分：")]),_c('dd',[_vm._v(" "+_vm._s(group.accountType)+" ")]),_c('dt',[_vm._v("権限：")]),_c('dd',[_c('div',{staticClass:"permission-container"},[(
                                                    group.permissionList
                                                        .show
                                                )?_c('div',{staticClass:"permission-item"},[_vm._v(" 閲覧 ")]):_vm._e(),(
                                                    group.permissionList.add
                                                )?_c('div',{staticClass:"permission-item"},[_vm._v(" 追加 ")]):_vm._e(),(
                                                    group.permissionList
                                                        .edit
                                                )?_c('div',{staticClass:"permission-item"},[_vm._v(" 編集 ")]):_vm._e(),(
                                                    group.permissionList
                                                        .delete
                                                )?_c('div',{staticClass:"permission-item"},[_vm._v(" 削除 ")]):_vm._e()])]),(!_vm.hasPermission(group))?_c('div',{staticClass:"permission-item"},[_c('dd',[_vm._v("権限がありません")])]):_vm._e()]),_c('v-divider'),_c('v-virtual-scroll',{attrs:{"items":group.members,"height":"100px","item-height":"24"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var item = ref.item;
return [_c('v-list-item',{key:item.employeeId},[_c('dl',[_c('dt',[_vm._v(" [ "+_vm._s(item.employeeId)+" ] ")]),_c('dd',[_vm._v(" "+_vm._s(item.employeeName)+" ")])])])]}}],null,true)}),_c('v-divider'),(_vm.isAdminEmployee)?_c('div',{staticClass:"button-container"},[_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","color":"primary","link":"","to":{
                                            name: 'PermissionForm',
                                            params: { id: group.groupId },
                                        }}},[_vm._v(" 編集 ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.openDelteConfirmDialog(
                                                group.groupId
                                            )}}},[_vm._v(" 削除 ")])],1):_c('p',{staticClass:"\n                                        text-center\n                                        non-operation-message\n                                    "},[_vm._v(" 操作権限がありません ")])],1)],1)],1)}),1)],1)]),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("削除確認 ")]),_c('v-card-text',[(_vm.deleteErrorMessage)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.deleteErrorMessage)+" ")]):_vm._e(),_vm._v(" データを削除してよろしいですか？")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.closeDeleteDialog}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.deleteGroup}},[_vm._v("削除")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }