//各テーブルの設定
import { DataTableHeader } from "vuetify";

export const SERVICE_ACCOUNT_HEADER: DataTableHeader[] = [
    {
        text: "サービス名",
        align: "center",
        value: "serviceName",
        width: "220",
    },
    {
        text: "URL",
        align: "center",
        value: "url",
        width: "300",
        filterable: false,
    },
    {
        text: "ログインID",
        align: "center",
        value: "loginId",
        width: "150",
        filterable: false,
    },
    {
        text: "ログインパス",
        align: "center",
        value: "loginPassword",
        width: "150",
        filterable: false,
    },
    {
        text: "Basic認証ID",
        align: "center",
        value: "basicAuthenticationId",
        width: "150",
        filterable: false,
    },
    {
        text: "Basic認証パス",
        align: "center",
        value: "basicAuthenticationPassword",
        width: "150",
        filterable: false,
    },
    {
        text: "管理DB",
        align: "center",
        value: "managementDatabase",
        width: "150",
        filterable: false,
    },
    {
        text: "備考",
        align: "center",
        value: "memo",
        width: "200",
        filterable: false,
    },
    {
        text: "操作",
        align: "center",
        value: "action",
        width: "120",
        filterable: false,
    },
];

export const SERVICE_PERMISSION_HEADER: DataTableHeader[] = [
    {
        text: "サービス名",
        align: "center",
        value: "serviceName",
        width: "100",
    },
    {
        text: "閲覧",
        align: "center",
        value: "permissionShow",
        width: "40",
        filterable: false,
        sortable: false,
    },
    {
        text: "追加",
        align: "center",
        value: "permissionAdd",
        width: "40",
        filterable: false,
        sortable: false,
    },
    {
        text: "編集",
        align: "center",
        value: "permissionEdit",
        width: "40",
        filterable: false,
        sortable: false,
    },
    {
        text: "削除",
        align: "center",
        value: "permissionDelete",
        width: "40",
        filterable: false,
        sortable: false,
    },
];

export const EMPLOYEE_PERMISSION_HEADER: DataTableHeader[] = [
    {
        text: "従業員名",
        align: "center",
        value: "employeeName",
        width: "100",
    },
    {
        text: "閲覧",
        align: "center",
        value: "permissionShow",
        width: "40",
        filterable: false,
        sortable: false,
    },
    {
        text: "追加",
        align: "center",
        value: "permissionAdd",
        width: "40",
        filterable: false,
        sortable: false,
    },
    {
        text: "編集",
        align: "center",
        value: "permissionEdit",
        width: "40",
        filterable: false,
        sortable: false,
    },
    {
        text: "削除",
        align: "center",
        value: "permissionDelete",
        width: "40",
        filterable: false,
        sortable: false,
    },
];
