










































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { serverPlanForm } from "@/types/modules/provider-plan";
import FormTypeClass from "@/models/class/form-type";
import { FormTypes } from "@/types/modules/common";

import AddProviderDialog from "@/components/parts/dialog/ProviderPlan/AddProviderDialog.vue";
import PageBackButton from "@/components/parts/PageBackButton.vue";

import { providerPlanStore } from "@/models/store/ProviderPlans/ProviderPlanStore";
import { providerStore } from "@/models/store/ProviderPlans/ProviderStore";
import { ProviderCompany } from "@/types/api/provider";

@Component({
    components: {
        AddProviderDialog,
        PageBackButton,
    },
})
export default class ServerPlanFormComponent extends Vue {
    @Prop({ type: String, default: null }) readonly id!: string | null;
    @Prop({ type: String, default: "new" })
    private readonly formType!: FormTypes;

    private serverPlanForm: serverPlanForm = {
        serverPlanId: 0,
        params: {
            providerName: "",
            zone: "",
            instanceName: "",
            core: 0,
            ram: 0,
            baseFee: 0,
            ipAddressFee: 0,
        },
    };

    private formTypeClass = new FormTypeClass(this.formType);

    private dialog = false;
    // フォームバリデーション
    private valid = true;
    private rules = {
        providerName: [(v: string) => !!v || "プロバイダ名を選択してください"],
        zone: [(v: string) => !!v || "ゾーン名を入力してください"],
        core: [
            (v: number) => v >= 0 || "コア数は0以上の数値で入力してください",
            (v: number) =>
                v < 1000 || "コア数は1000以下の数値で入力してください",
            (v: number) => v === 0 || !!v || "コア数を入力してください",
        ],
        ram: [
            (v: number) => v >= 0 || "RAMは0以上の数値で入力してください",
            (v: number) => v < 1000 || "RAMは1000以下の数値で入力してください",
            (v: number) => v === 0 || !!v || "RAMを入力してください",
        ],
        baseFee: [
            (v: number) => v >= 0 || "基本料金は0円以上で入力してください",
            (v: number) => v === 0 || !!v || "基本料金を入力してください",
        ],
        ipAddressFee: [
            (v: number) =>
                v >= 0 || "IPアドレス料金は0円以上で入力してください",
            // NOTE: ipアドレス料金の保存カラムはsmallintの為
            (v: number) =>
                v < 65536 || "IPアドレス料金は65536円以下で入力してください",
            (v: number) => v === 0 || !!v || "IPアドレス料金を入力してください",
        ],
    };

    public created(): void {
        providerPlanStore.initStore();
        providerStore.getProviderCompanyList();
        this.formTypeClass = new FormTypeClass(this.formType);
        if (this.formTypeClass.isRegisterPage()) return;
        // パラメータでstring型でidが来るのでnumber型に変換
        const targetId = Number(this.id);
        this.updateFormSetting(targetId);
    }

    /**
     * idから該当のプラン情報を取得し、フォームにセット
     */
    private async updateFormSetting(targetId: number): Promise<void> {
        await providerPlanStore.getServerPlan(targetId);
        const serverPlan = providerPlanStore.serverPlan;

        this.serverPlanForm.serverPlanId = serverPlan.planId;
        this.serverPlanForm.params.providerName = serverPlan.providerName;
        this.serverPlanForm.params.zone = serverPlan.zone ?? "";
        this.serverPlanForm.params.instanceName = serverPlan.instanceName ?? "";
        this.serverPlanForm.params.core = serverPlan.core ?? 0;
        this.serverPlanForm.params.ram = serverPlan.ram ?? 0;
        this.serverPlanForm.params.baseFee = serverPlan.baseFee ?? 0;
        this.serverPlanForm.params.ipAddressFee = serverPlan.ipAddressFee ?? 0;
    }
    /**
     * プロバイダプランの新規登録
     */
    private async registerProviderPlan(): Promise<void> {
        this.formTypeClass.isRegisterPage()
            ? await providerPlanStore.postRegisterServerPlan(
                  this.serverPlanForm
              )
            : await providerPlanStore.putUpdateServerPlan(this.serverPlanForm);

        if (providerPlanStore.postStatus) {
            this.$router.push("/provider-plan/list");
        }
    }

    public get displayTitle(): string {
        return this.formTypeClass.isRegisterPage()
            ? "サーバープランの作成"
            : "サーバープランの編集";
    }

    public get displayButtonMessage(): string {
        return this.formTypeClass.isRegisterPage()
            ? "プランを作成"
            : "プランを更新";
    }

    public get providerCompanyList(): ProviderCompany[] {
        return providerStore.providerCompanyList;
    }

    public get postErrorMessage(): string {
        return providerPlanStore.postErrorMessage;
    }

    private closeDialog(dialogFlag: boolean): void {
        this.dialog = dialogFlag;
    }
}
