var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('page-back-button'),_c('div',{staticClass:"page-container"},[_c('h1',[_vm._v("プラットフォーム情報")]),_c('div',{staticClass:"button-container"},[_c('v-btn',{attrs:{"link":"","to":"/platform/form","color":"primary","small":""}},[_vm._v(" 新規プラットフォームを追加 ")])],1),_c('div',{staticClass:"list-container"},[_c('div',{staticClass:"search"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"システム名","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.hasError)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.platformList,"search":_vm.search,"items-per-page":1000,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(item.url))])]}},{key:"item.loginId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clip-cell"},[_c('div',[_vm._v(" "+_vm._s(item.loginId)+" ")]),(item.loginId)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.writeToClipboard(item.loginId)}}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-clipboard-outline")])],1):_vm._e()],1)]}},{key:"item.loginPassword",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clip-cell"},[(item.loginPassword)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.writeToClipboard(item.loginPassword)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clipboard-outline")])],1):_vm._e()],1)]}},{key:"item.basicAuthenticationId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clip-cell"},[_vm._v(" "+_vm._s(item.basicAuthenticationId)+" "),(item.basicAuthenticationId)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.writeToClipboard(item.basicAuthenticationId)}}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-clipboard-outline")])],1):_vm._e()],1)]}},{key:"item.basicAuthenticationPassword",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clip-cell"},[(item.basicAuthenticationPassword)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.writeToClipboard(
                                    item.basicAuthenticationPassword
                                )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clipboard-outline")])],1):_vm._e()],1)]}},{key:"item.action",fn:function(ref){
                                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editPlatform(item.platformId)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"secondary","icon":""},on:{"click":function($event){return _vm.openDeleteConfirmDialog(item.platformId)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")])],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("削除確認 ")]),_c('v-card-text',[(_vm.hasError)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_vm._v(" データを削除してよろしいですか？")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"color":"secondary darken-1","text":""},on:{"click":_vm.deletePlatform}},[_vm._v("削除")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }