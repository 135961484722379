import { FormTypes } from "@/types/modules/common";

export default class FormTypeClass {
    private readonly _formType!: FormTypes;

    constructor(formType: FormTypes) {
        this._formType = formType;
    }

    public isRegisterPage(): boolean {
        return this._formType === "new";
    }
}
