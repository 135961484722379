














import { Component, Watch, Vue } from "vue-property-decorator";
import HeaderComponent from "./components/globals/Header.vue";
import NavigationComponent from "./components/globals/Navigation.vue";
import { errorStore } from "@/models/store/ErrorStore";
import { INTERNAL_SERVER_ERROR } from "./libs/util";
import { authStore } from "./models/store/AuthStore";

@Component({
    components: {
        HeaderComponent,
        NavigationComponent,
    },
})
export default class App extends Vue {
    private drawer = false;

    public setDrawer(navigationFlag: boolean): void {
        if (this.$route.name !== "Login") {
            this.drawer = navigationFlag;
        }
    }

    public get statusCode(): number {
        return errorStore.statusCode;
    }

    @Watch("statusCode")
    public hasSystemError(code: number): void {
        if (code === INTERNAL_SERVER_ERROR) {
            this.$router.push("/error");
        }
    }

    public get isAuthenticated(): boolean {
        return authStore.isAuthenticated;
    }

    @Watch("isAuthenticated")
    public isAuthenticatedChanged(status: boolean): void {
        if (!status) {
            this.$router.push("/");
        }
    }
}
