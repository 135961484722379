






































































































































































































import { Component, Vue } from "vue-property-decorator";
import { PermissionGroup, PermissionGroupList } from "@/types/api/permission";

import AddGroupDialog from "@/components/parts/dialog/Permission/AddGroupDialog.vue";
import PageBackButton from "@/components/parts/PageBackButton.vue";

import { permissionGroupListStore } from "@/models/store/Permissions/PermissionGroupListStore";
import { systemStore } from "@/models/store/SystemStore";
import { authStore } from "@/models/store/AuthStore";

@Component({
    components: {
        AddGroupDialog,
        PageBackButton,
    },
})
export default class PermissionListComponent extends Vue {
    private showGroupList: PermissionGroupList["group_list"] = [];
    private systemList: string[] = ["すべて"];
    private systemName = this.systemList[0];

    private addDialog = false;
    private deleteDialog = false;
    private deleteGroupId = 0;

    public async created(): Promise<void> {
        permissionGroupListStore.refreshError();
        await permissionGroupListStore.getPermissionGroupList();
        this.showGroupList = this.groupList;
        this.fetchSystemList();
    }

    /**
     * システム一覧を取得
     */
    private async fetchSystemList(): Promise<void> {
        await systemStore.getSystemList();
        systemStore.systemList.forEach((system) =>
            this.systemList.push(system.systemName)
        );
    }

    /**
     * 絞り込み
     */
    private filterGroupBySystemName(): void {
        if (this.systemName === "すべて") {
            this.showGroupList = this.groupList;
        } else {
            this.showGroupList = this.groupList.filter(
                (group) => group.systemName === this.systemName
            );
        }
    }

    /**
     * 権限グループの削除処理
     */
    private async deleteGroup(): Promise<void> {
        await permissionGroupListStore.deletePermissionGroup(
            this.deleteGroupId
        );
        if (permissionGroupListStore.deleteStatus) {
            this.closeDeleteDialog();
            await permissionGroupListStore.getPermissionGroupList();
            this.filterGroupBySystemName();
        }
    }

    /**
     * 権限所持の判定
     */
    public hasPermission(group: PermissionGroup): boolean {
        return (
            group.permissionList.show ||
            group.permissionList.add ||
            group.permissionList.edit ||
            group.permissionList.delete
        );
    }

    private openDelteConfirmDialog(groupId: number): void {
        this.deleteDialog = true;
        this.deleteGroupId = groupId;
    }

    private closeDeleteDialog(): void {
        this.deleteDialog = false;
        this.deleteGroupId = 0;
        permissionGroupListStore.refreshError();
    }

    private async closeAddDialog(dialogFlag: boolean): Promise<void> {
        this.addDialog = dialogFlag;
        await permissionGroupListStore.getPermissionGroupList();
        this.filterGroupBySystemName();
    }

    public get groupList(): PermissionGroupList["group_list"] {
        return permissionGroupListStore.groupList;
    }

    public get isAdminEmployee(): boolean {
        return authStore.employeeType === "管理ユーザー";
    }

    public get errorMessage(): string {
        return permissionGroupListStore.errorMessage;
    }

    public get deleteErrorMessage(): string {
        return permissionGroupListStore.deleteErrorMessage;
    }
}
