import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { Platform } from "@/types/Platform/api/platform";
import { authStore } from "../AuthStore";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { PlatformForm } from "@/types/Platform/modules/platform-type";

@Module({
    name: "platform",
    dynamic: true,
    store,
    namespaced: true,
})
class PlatformStore extends VuexModule {
    private readonly baseUrl = "/api/platform";
    private _platformList: Platform[] = [];
    private _platform: Platform | null = null;

    private _targetPlatformId = 0;

    private _hasError = false;
    private _errorMessage = "";

    public get platformList(): Platform[] {
        return this._platformList;
    }

    public get platform(): Platform | null {
        return this._platform;
    }

    public get hasError(): boolean {
        return this._hasError;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    @Mutation
    private setPlatformList(data: Platform[]): void {
        this._platformList = data;
    }

    @Mutation
    private setPlatform(data: Platform | null): void {
        this._platform = data;
    }

    @Mutation
    private setTargetPlatformId(id: number): void {
        this._targetPlatformId = id;
    }

    @Mutation
    private setHasError(status: boolean): void {
        this._hasError = status;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Action({ rawError: true })
    public updateTargetId(id: number): void {
        this.setTargetPlatformId(id);
    }

    @Action({ rawError: true })
    public initStore(): void {
        this.setHasError(false);
        this.setErrorMessage("");
    }

    @Action({ rawError: true })
    public async getPlatformList(): Promise<void> {
        const response = await axios
            .get(`${this.baseUrl}/index`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }
        const result = response.data;
        if (result.status === "error") {
            this.setHasError(true);
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setHasError(false);
        this.setErrorMessage("");
        this.setPlatformList(result.data);
    }

    @Action({ rawError: true })
    public async getPlatform(): Promise<void> {
        const response = await axios
            .get(`${this.baseUrl}/${this._targetPlatformId}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }
        const result = response.data;
        if (result.status === "error") {
            this.setHasError(true);
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setHasError(false);
        this.setErrorMessage("");
        this.setPlatform(result.data);
    }

    @Action({ rawError: true })
    public async registerPlatform(request: PlatformForm): Promise<void> {
        const response = await axios
            .post(this.baseUrl, request, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);
        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }
        const result = response.data;
        if (result.status === "error") {
            this.setHasError(true);
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setHasError(false);
        this.setErrorMessage("");
    }

    @Action({ rawError: true })
    public async updatePlatform(request: PlatformForm): Promise<void> {
        const response = await axios
            .put(`${this.baseUrl}/${this._targetPlatformId}`, request, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);
        console.log(response);
        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }
        const result = response.data;
        if (result.status === "error") {
            this.setHasError(true);
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setHasError(false);
        this.setTargetPlatformId(0);
        this.setErrorMessage("");
    }

    @Action({ rawError: true })
    public async deletePlatform(): Promise<void> {
        const response = await axios
            .delete(`${this.baseUrl}/${this._targetPlatformId}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }
        const result = response.data;
        if (result.status === "error") {
            this.setHasError(true);
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setHasError(false);
        this.setTargetPlatformId(0);
        this.setErrorMessage("");
    }
}

export const platformStore = getModule(PlatformStore);
