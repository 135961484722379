



































import { SystemForm } from "@/types/modules/server";
import { Component, Vue } from "vue-property-decorator";
import { systemStore } from "@/models/store/SystemStore";

@Component
export default class AddSystemDialogComponent extends Vue {
    private systemForm: SystemForm = {
        systemName: "",
    };

    // フォームバリデーション
    private valid = true;
    private required = [(v: string) => !!v || "システム名を入力してください"];

    /**
     * システム追加
     */
    private async postRegister(): Promise<void> {
        const result = await systemStore.postRegisterSystem(this.systemForm);
        if (result) {
            this.closeDialog();
        }
    }

    public get errorMessage(): string {
        return systemStore.errorMessage;
    }

    public closeDialog(): void {
        systemStore.initError();
        this.$emit("input", false);
    }
}
