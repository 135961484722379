
































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormTypeClass from "@/models/class/form-type";

import AddSystemDialog from "@/components/parts/dialog/Server/AddSystemDialog.vue";
import AddCategoryDialog from "@/components/parts/dialog/Server/AddCategoryDialog.vue";
import AddProviderDialog from "@/components/parts/dialog/ProviderPlan/AddProviderDialog.vue";
import PageBackButton from "@/components/parts/PageBackButton.vue";

import { FormTypes } from "@/types/modules/common";
import { contractStatusValues, ServerForm } from "@/types/modules/server";
import { SystemInfo } from "@/types/api/system";
import { CategoryInfo, ServerInfo } from "@/types/api/server";
import { ProviderInfo, ProviderPlanAbbreviation } from "@/types/api/provider";

import { systemStore } from "@/models/store/SystemStore";
import { categoryStore } from "@/models/store/Servers/CategoryStore";
import { providerStore } from "@/models/store/Providers/ProviderStore";
import { providerPlanNameStore } from "@/models/store/Servers/ProviderPlanNameStore";
import { serverFormStore } from "@/models/store/Servers/ServerFormStore";

@Component({
    components: {
        AddSystemDialog,
        AddCategoryDialog,
        AddProviderDialog,
        PageBackButton,
    },
})
export default class ServerFormComponent extends Vue {
    @Prop({ type: String, default: "new" }) readonly formType!: FormTypes;

    private formTypeClass = new FormTypeClass(this.formType);

    private contractStatusList = contractStatusValues;

    private serverForm: ServerForm = {
        largeCategory: "",
        smallCategory: "",
        system: "",
        name: "",
        ip: "",
        domains: [""],
        providerName: "",
        serverPlanId: 0,
        storagePlanId: 0,
        hasExtraStorage: false,
        extraStoragePlanIdList: {
            extraStoragePlan1: 0,
            extraStoragePlan2: 0,
            extraStoragePlan3: 0,
        },
        bastionHost: false,
        networkFee: 0,
        memo: "",
        contractStatus: this.contractStatusList[0],
        proxy: false,
        squidVersion: "",
        squidPort: 3128,
        whiteListServer: false,
        costLine: false,
        accrete: false,
    };

    // ダイアログ関連
    private addLargeCategoryDialog = false;
    private addSmallCategoryDialog = false;
    private addProviderDialog = false;
    private addSystemDialog = false;

    // フォームバリデーション
    private valid = true;
    private rules = {
        largeCategory: [(v: string) => !!v || "大カテゴリを選択してください"],
        smallCategory: [(v: string) => !!v || "小カテゴリを選択してください"],
        systemName: [(v: string) => !!v || "システムを選択してください"],
        providerName: [(v: string) => !!v || "プロバイダ名を選択してください"],
        ip: [(v: string) => !!v || "IPアドレスを入力してください"],
        domain: [(v: string) => !!v || "ドメインを入力してください"],
        plan: [(v: string) => !!v || "プランを選択してください"],
        networkFee: [
            (v: number) =>
                v >= 0 || "想定ネットワーク料金は0円以上で入力してください",
            // NOTE: 想定ネットワーク料金の保存カラムはintの為
            (v: number) =>
                v < 4294967295 ||
                "想定ネットワーク料金は4294967295円以下で入力してください",
            (v: number) =>
                v === 0 || !!v || "想定ネットワーク料金を入力してください",
        ],
    };

    public get requiredSquidVersionError(): string {
        return this.serverForm.proxy && this.serverForm.squidVersion === ""
            ? "squidバージョンを入力してください"
            : "";
    }

    public get requiredSquidPortError(): string {
        return this.serverForm.proxy && this.serverForm.squidPort <= 0
            ? "squidポートは1以上で入力してください"
            : "";
    }

    public created(): void {
        this.formTypeClass = new FormTypeClass(this.formType);
        serverFormStore.refreshError();

        this.fetchCategories();
        systemStore.getSystemList();
        providerStore.getProviderList();
        providerPlanNameStore.getServerPlanNameList();
        providerPlanNameStore.getStoragePlanNameList();

        if (this.formTypeClass.isRegisterPage()) return;
        this.updateFormSetting();
    }

    @Watch("serverForm.hasExtraStorage")
    public resetExstraStoragePlan(): void {
        if (!this.serverForm.hasExtraStorage) {
            this.serverForm.extraStoragePlanIdList.extraStoragePlan1 = 0;
            this.serverForm.extraStoragePlanIdList.extraStoragePlan2 = 0;
            this.serverForm.extraStoragePlanIdList.extraStoragePlan3 = 0;
        }
    }

    public get largeCategoryList(): CategoryInfo[] {
        return categoryStore.largeCategory;
    }

    public get smallCategoryList(): CategoryInfo[] {
        return categoryStore.smallCategory;
    }

    private fetchCategories(): void {
        categoryStore.getCategoryList("large");
        categoryStore.getCategoryList("small");
    }

    public get systemList(): SystemInfo[] {
        return systemStore.systemList;
    }

    public get providerList(): ProviderInfo[] {
        return providerStore.providerList;
    }

    public get serverPlanNameList(): ProviderPlanAbbreviation[] {
        return providerPlanNameStore.serverPlanList;
    }

    public get storagePlanNameList(): ProviderPlanAbbreviation[] {
        return providerPlanNameStore.storagePlanList;
    }

    public get displayServerPlanNameList(): ProviderPlanAbbreviation[] {
        return this.serverPlanNameList.filter(
            (serverPlan: ProviderPlanAbbreviation) =>
                serverPlan.providerName === this.serverForm.providerName
        );
    }

    public get displayStoragePlanNameList(): ProviderPlanAbbreviation[] {
        return this.storagePlanNameList.filter(
            (storagePlan: ProviderPlanAbbreviation) =>
                storagePlan.providerName === this.serverForm.providerName
        );
    }

    private async updateFormSetting(): Promise<void> {
        await serverFormStore.getServer(serverFormStore.serverId);
        if (this.server === null) return;
        serverFormStore.updateServerId(this.server.serverId);
        this.serverForm.largeCategory = this.server.largeCategory.categoryName;
        this.serverForm.smallCategory = this.server.smallCategory.categoryName;
        this.serverForm.system = this.server.system.systemName;
        this.serverForm.providerName = this.server.storagePlan.providerName;
        this.serverForm.name = this.server.name;
        this.serverForm.ip = this.server.ip;
        this.serverForm.domains = this.server.domain;
        this.serverForm.serverPlanId = this.server.serverPlan.planId;
        this.serverForm.storagePlanId = this.server.storagePlan.planId;
        this.serverForm.hasExtraStorage =
            this.server.extraStoragePlan.length > 0;
        this.serverForm.extraStoragePlanIdList.extraStoragePlan1 =
            this.server.extraStoragePlan[0]?.planId ?? 0;
        this.serverForm.extraStoragePlanIdList.extraStoragePlan2 =
            this.server.extraStoragePlan[1]?.planId ?? 0;
        this.serverForm.extraStoragePlanIdList.extraStoragePlan3 =
            this.server.extraStoragePlan[2]?.planId ?? 0;
        this.serverForm.bastionHost = this.server.bastionHost;
        this.serverForm.networkFee = this.server.networkFee;
        this.serverForm.memo = this.server.memo;
        this.serverForm.contractStatus = this.server.contractStatus;
        this.serverForm.proxy = !(this.server.proxyInfo === null);
        this.serverForm.squidVersion = this.server.proxyInfo
            ? this.server.proxyInfo.squidVersion
            : "";
        this.serverForm.squidPort = this.server.proxyInfo
            ? this.server.proxyInfo?.squidPort
            : 0;
        this.serverForm.whiteListServer = !(
            this.server.whiteServerInfo === null
        );
        this.serverForm.costLine = this.server.whiteServerInfo
            ? this.server.whiteServerInfo.cost
            : false;
        this.serverForm.accrete = this.server.whiteServerInfo
            ? this.server.whiteServerInfo.accrete
            : false;
    }

    private addInput(): void {
        this.serverForm.domains.push("");
    }

    private removeInput(index: number): void {
        this.serverForm.domains.splice(index, 1);
    }

    public async submit(): Promise<void> {
        this.formTypeClass.isRegisterPage()
            ? await serverFormStore.postRegisterServer(this.serverForm)
            : await serverFormStore.postUpdateServer(this.serverForm);
        if (serverFormStore.postStatus) {
            this.$router.push({
                name: "ServerAccountForm",
                params: { formType: this.formType },
            });
        }
    }

    public get displayTitle(): string {
        return this.formTypeClass.isRegisterPage()
            ? "サーバー情報登録"
            : "サーバー情報更新";
    }

    public get displaySystemLabel(): string {
        return this.systemList.length === 0
            ? "システム名を追加してください"
            : "システム名";
    }

    public get displayButtonMessage(): string {
        return this.formTypeClass.isRegisterPage()
            ? "サーバー基本情報を保存"
            : "サーバー基本情報を更新";
    }

    public closeCategoryDialog(dialogFlag: boolean): void {
        this.addLargeCategoryDialog = dialogFlag;
        this.addSmallCategoryDialog = dialogFlag;
        this.fetchCategories();
    }

    public closeSystemDialog(dialogFlag: boolean): void {
        this.addSystemDialog = dialogFlag;
        systemStore.getSystemList();
    }

    public closeProviderDialog(dialogFlag: boolean): void {
        this.addProviderDialog = dialogFlag;
        providerStore.getProviderList();
    }

    public get server(): ServerInfo | null {
        return serverFormStore.server;
    }

    public get fetchErrorMessage(): string {
        return serverFormStore.fetchErrorMessage;
    }

    public get postErrorMessage(): string {
        return serverFormStore.postErrorMessage;
    }
}
