import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { SshAccountForm } from "@/types/modules/server";
import {
    POST_REGISTER_SERVER_ACCOUNT,
    POST_UPDATE_SERVER_ACCOUNT,
} from "@/configs/ApiConfig";
import { serverFormStore } from "./ServerFormStore";
import { authStore } from "../AuthStore";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { errorStore } from "../ErrorStore";

@Module({
    name: "accountForm",
    dynamic: true,
    store,
    namespaced: true,
})
class AccountFormStore extends VuexModule {
    private _sshAccountId = 0;
    private _postErrorMessage = "";
    private _postStatus = false;

    public get sshAccountId(): number {
        return this._sshAccountId;
    }

    public get postErrorMessage(): string {
        return this._postErrorMessage;
    }

    public get postStatus(): boolean {
        return this._postStatus;
    }

    @Mutation
    private setSshAccountId(id: number): void {
        this._sshAccountId = id;
    }

    @Mutation
    private setPostErrorMessage(message: string): void {
        this._postErrorMessage = message;
    }

    @Mutation
    private setPostStatus(status: boolean): void {
        this._postStatus = status;
    }

    @Action({ rawError: true })
    public refreshError(): void {
        this.setPostErrorMessage("");
        this.setPostStatus(false);
    }

    @Action({ rawError: true })
    public updateSshAccountId(id: number): void {
        this.setSshAccountId(id);
    }

    @Action({ rawError: true })
    public async postAccountRegister(request: SshAccountForm[]): Promise<void> {
        const response = await axios
            .post(
                `${POST_REGISTER_SERVER_ACCOUNT}/${serverFormStore.serverId}`,
                request,
                {
                    headers: {
                        Authorization: `Bearer ${authStore.token}`,
                    },
                }
            )
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            this.setPostStatus(false);
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            this.setPostStatus(false);
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setPostStatus(false);
            this.setPostErrorMessage(result.data.message);
            return;
        }
        this.setPostStatus(true);
        this.setPostErrorMessage("");
    }

    @Action({ rawError: true })
    public async postAccountUpdate(request: SshAccountForm[]): Promise<void> {
        const response = await axios
            .put(
                `${POST_UPDATE_SERVER_ACCOUNT}/${serverFormStore.serverId}`,
                request,
                {
                    headers: {
                        Authorization: `Bearer ${authStore.token}`,
                    },
                }
            )
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            this.setPostStatus(false);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            this.setPostStatus(false);
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setPostStatus(false);
            this.setPostErrorMessage(result.data.message);
            return;
        }
        this.setPostStatus(true);
        this.setPostErrorMessage("");
    }
}

export const accountFormStore = getModule(AccountFormStore);
