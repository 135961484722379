














































































































































































import { Component, Vue } from "vue-property-decorator";
import { ServerFeeInfo } from "@/types/api/fee";
import { CategoryInfo } from "@/types/api/server";

import PageBackButton from "@/components/parts/PageBackButton.vue";

import { serverFeeStore } from "@/models/store/Fees/ServerFeeStore";
import { categoryStore } from "@/models/store/Servers/CategoryStore";

@Component({
    components: {
        PageBackButton,
    },
})
export default class FeeListComponent extends Vue {
    public created(): void {
        categoryStore.getCategoryList("small");
        serverFeeStore.getServerFeeList();
    }

    public get categoryList(): CategoryInfo[] {
        return categoryStore.smallCategory;
    }

    public get serverFeeList(): ServerFeeInfo[] {
        return serverFeeStore.serverFeeList;
    }

    public get errorMessage(): string {
        return serverFeeStore.errorMessage;
    }

    private writeToClipboard(index: number): void {
        const body = document.getElementById(
            "fee-table" + index
        ) as HTMLElement;
        const range = document.createRange();
        range.selectNodeContents(body);

        const addSelection = window.getSelection() as Selection;
        addSelection.addRange(range);
        document.execCommand("copy");

        const removeSelection = window.getSelection() as Selection;
        removeSelection.removeRange(range);
    }
}
