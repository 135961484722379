import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { PermissionGroupList } from "@/types/api/permission";
import {
    DELETE_PERMISSION_GROUP,
    GET_PERMISSION_GROUP_LIST,
} from "@/configs/ApiConfig";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { authStore } from "../AuthStore";

@Module({
    name: "permissionGroupList",
    dynamic: true,
    store,
    namespaced: true,
})
class PermissionGroupListStore extends VuexModule {
    private _groupList: PermissionGroupList["group_list"] = [];
    private _errorMessage = "";
    private _deleteErrorMessage = "";

    private _deleteStatus = false;

    public get groupList(): PermissionGroupList["group_list"] {
        return this._groupList;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    public get deleteErrorMessage(): string {
        return this._deleteErrorMessage;
    }

    public get deleteStatus(): boolean {
        return this._deleteStatus;
    }

    @Mutation
    private setGroupList(data: PermissionGroupList["group_list"]): void {
        this._groupList = data;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Mutation
    private setDeleteErrorMessage(message: string): void {
        this._deleteErrorMessage = message;
    }

    @Mutation
    private setDeleteStatus(status: boolean): void {
        this._deleteStatus = status;
    }

    @Action({ rawError: true })
    public refreshError(): void {
        this.setErrorMessage("");
        this.setDeleteErrorMessage("");
        this.setDeleteStatus(false);
    }

    @Action({ rawError: true })
    public async getPermissionGroupList(): Promise<void> {
        const response = await axios
            .get(GET_PERMISSION_GROUP_LIST, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setErrorMessage("");
        this.setGroupList(result.data);
    }

    @Action({ rawError: true })
    public async deletePermissionGroup(groupId: number): Promise<void> {
        const response = await axios
            .delete(`${DELETE_PERMISSION_GROUP}/${groupId}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            this.setDeleteStatus(false);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setDeleteErrorMessage(result.data.message);
            return;
        }
        this.setDeleteErrorMessage("");
        this.setDeleteStatus(true);
    }
}

export const permissionGroupListStore = getModule(PermissionGroupListStore);
