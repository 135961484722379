


































import { Component, Vue } from "vue-property-decorator";

import { NavigationInfo } from "@/types/modules/navigation";
import { authStore } from "@/models/store/AuthStore";

@Component
export default class NavigationComponent extends Vue {
    private items: NavigationInfo[] = [
        {
            icon: "mdi-view-list",
            title: "サーバ情報",
            link: "/server/list",
        },
        {
            icon: "mdi-key-variant",
            title: "サーバ閲覧権限管理",
            link: "/server-permission/list",
        },
        {
            icon: "mdi-notebook",
            title: "プラン情報",
            link: "/provider-plan/list",
        },
        { icon: "mdi-alarm-panel", title: "料金情報", link: "/fee/list" },
        {
            icon: "mdi-database-plus",
            title: "プラットフォーム情報",
            link: "/platform/list",
        },
        {
            icon: "mdi-account-circle",
            title: "アカウント情報",
            link: "/service-account/list",
        },
        {
            icon: "mdi-key-variant",
            title: "サービス権限管理",
            link: "/service-permission",
        },
    ];

    public get employeeName(): string {
        return authStore.employeeName;
    }

    public get loginId(): string {
        return authStore.loginId;
    }

    public get profileImageUrl(): string {
        return authStore.profileImageUrl;
    }
}
