import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";

@Module({
    name: "error",
    dynamic: true,
    store,
    namespaced: true,
})
class ErrorStore extends VuexModule {
    private _statusCode = 0;
    private _message = "";

    public get statusCode(): number {
        return this._statusCode;
    }

    public get message(): string {
        return this._message;
    }

    @Mutation
    private setStatusCode(statusCode: number): void {
        this._statusCode = statusCode;
    }

    @Mutation
    private setMessage(message: string): void {
        this._message = message;
    }

    @Action({ rawError: true })
    public setErrorInfo(status: number, message: string): void {
        this.setStatusCode(status);
        this.setMessage(message);
    }
}

export const errorStore = getModule(ErrorStore);
