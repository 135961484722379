import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { ServicePermissionInfo } from "@/types/api/service-account";
import {
    GET_SERVICE_PERMISSION_LIST,
    GET_EMPLOYEE_PERMISSION_LIST,
} from "@/configs/ApiConfig";
import { authStore } from "../AuthStore";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";

@Module({
    name: "servicePermission",
    dynamic: true,
    store,
    namespaced: true,
})
class ServicePermissionStore extends VuexModule {
    private _servicePermissionList: ServicePermissionInfo[] = [];

    private _employeePermissionList: ServicePermissionInfo[] = [];

    private _errorMessage = "";
    private _deleteErrorMessage = "";

    private _postStatus = false;
    private _deleteStatus = false;

    public get servicePermissionList(): ServicePermissionInfo[] {
        return this._servicePermissionList;
    }

    public get employeePermissionList(): ServicePermissionInfo[] {
        return this._employeePermissionList;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    public get deleteErrorMessage(): string {
        return this._deleteErrorMessage;
    }

    public get postStatus(): boolean {
        return this._postStatus;
    }

    public get deleteStatus(): boolean {
        return this._deleteStatus;
    }

    @Mutation
    private setServicePermissionList(data: ServicePermissionInfo[]): void {
        this._servicePermissionList = data;
    }

    @Mutation
    private setServicePermissionEmployee(data: ServicePermissionInfo[]): void {
        this._employeePermissionList = data;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Mutation
    private setDeleteErrorMessage(message: string): void {
        this._deleteErrorMessage = message;
    }

    @Mutation
    private setPostStatus(status: boolean): void {
        this._postStatus = status;
    }

    @Mutation
    private setDeleteStatus(status: boolean): void {
        this._deleteStatus = status;
    }

    @Action({ rawError: true })
    public initStore(): void {
        this.setPostStatus(false);
        this.setDeleteStatus(false);
        this.setErrorMessage("");
        this.setDeleteErrorMessage("");
    }

    @Action({ rawError: true })
    public async getServicePermissionList(): Promise<void> {
        const response = await axios
            .get(GET_SERVICE_PERMISSION_LIST, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setErrorMessage("");
        this.setServicePermissionList(result.data);
    }

    @Action({ rawError: true })
    public async getEmployeePermissionList(): Promise<void> {
        const response = await axios
            .get(GET_EMPLOYEE_PERMISSION_LIST, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setErrorMessage("");
        this.setServicePermissionEmployee(result.data);
    }
}

export const servicePermissionStore = getModule(ServicePermissionStore);
