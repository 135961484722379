var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clip-cell"},[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.permissionShow",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clip-cell"},[_c('v-checkbox',{attrs:{"input-value":item.permissionList.show}})],1)]}},{key:"item.permissionAdd",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clip-cell"},[_c('v-checkbox',{attrs:{"input-value":item.permissionList.add}})],1)]}},{key:"item.permissionEdit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clip-cell"},[_c('v-checkbox',{attrs:{"input-value":item.permissionList.edit}})],1)]}},{key:"item.permissionDelete",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clip-cell"},[_c('v-checkbox',{attrs:{"input-value":item.permissionList.delete}})],1)]}}])},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }