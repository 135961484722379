export const POST_LOGIN = "/api/auth";

// 権限関連
export const POST_PERMISSION_GROUP_REGISTER = "/api/permission/group/register";
export const GET_PERMISSION_GROUP_LIST = "/api/permission/group/index";
export const GET_PERMISSION_GROUP = "/api/permission/group";
export const DELETE_PERMISSION_GROUP = "/api/permission/group";
export const GET_NONMEMBERSHIP_GROUP_EMPLOYEE =
    "/api/permission/non-membership";
export const POST_GROUP_PERMISSION_UPDATE =
    "/api/permission/group/permission-type/update";
export const POST_REGISTER_EMPLOYEE_MEMBERSHIP = "/api/permission/membership";
export const DELETE_EMPLOYEE_MEMBERSHIP = "/api/permission/membership";

// サーバー関連
export const GET_CATEGORY_LIST = "/api/category/index";
export const POST_CATEGORY_REGISTER = "/api/category/register";
export const GET_SERVER_LIST = "/api/server/index";
export const GET_SERVER = "/api/server";
export const POST_REGISTER_SERVER = "/api/server/register";
export const POST_UPDATE_SERVER = "/api/server/update";
export const DELETE_SERVER_AND_ACCOUNT = "/api/server";
export const POST_REGISTER_SERVER_ACCOUNT = "/api/server/account/register";
export const POST_UPDATE_SERVER_ACCOUNT = "/api/server/account/update";
export const GET_BELONGS_PERMISSION_GROUP_INDEX =
    "/api/employee/belongs-permission-group/index";

// プラン関連
export const GET_PROVIDER_COMPANY_INDEX = "/api/provider/index";
export const POST_PROVIDER_REGISTER = "/api/provider/register";
export const GET_SERVER_PLAN_INDEX = "/api/server-plan/index";
export const GET_SERVER_PLAN = "/api/server-plan";
export const POST_SERVER_PLAN_REGISTER = "/api/server-plan/register";
export const POST_SERVER_PLAN_UPDATE = "/api/server-plan/update";
export const DELETE_SERVER_PLAN = "/api/server-plan/delete";
export const GET_SERVER_PLAN_NAME_LIST = "/api/server-plan/plan-name/index";

export const GET_STORAGE_PLAN_INDEX = "/api/storage-plan/index";
export const GET_STORAGE_PLAN = "/api/storage-plan";
export const POST_STORAGE_PLAN_REGISTER = "/api/storage-plan/register";
export const POST_STORAGE_PLAN_UPDATE = "/api/storage-plan/update";
export const DELETE_STORAGE_PLAN = "/api/storage-plan/delete";
export const GET_STORAGE_PLAN_NAME_LIST = "/api/storage-plan/plan-name/index";

// システム関連
export const GET_SYSTEM_LIST = "/api/system/index";
export const POST_SYSTEM_REGISTER = "/api/system/register";

// 料金関連
export const GET_SERVER_FEE_LIST = "/api/usage-fee/index";

// サービスアカウント関連
export const GET_SERVICE_ACCOUNT_LIST = "/api/service-account/index";
export const GET_SERVICE_ACCOUNT = "/api/service-account";
export const POST_SERVICE_ACCOUNT_REGISTER = "/api/service-account/register";
export const POST_SERVICE_ACCOUNT_UPDATE = "/api/service-account/update";
export const DELETE_SERVICE_ACCOUNT = "/api/service-account/delete";
export const DELETE_SERVICE_PERMISSION = "/api/service-permission/delete";
export const GET_EMPLOYEE_PERMISSION_LIST =
    "/api/service-permission/employee/index";
export const GET_SERVICE_PERMISSION_LIST =
    "/api/service-permission/service/index";
export const POST_SERVICE_PERMISSION_REGISTER =
    "/api/permission/service-permission/register";
