import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { GET_SERVER_FEE_LIST } from "@/configs/ApiConfig";
import { authStore } from "../AuthStore";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { errorStore } from "../ErrorStore";
import { ServerFeeInfo } from "@/types/api/fee";

@Module({
    name: "serverFee",
    dynamic: true,
    store,
    namespaced: true,
})
class ServerFeeStore extends VuexModule {
    private _serverFeeList: ServerFeeInfo[] = [];
    private _errorMessage = "";

    public get serverFeeList(): ServerFeeInfo[] {
        return this._serverFeeList;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    @Mutation
    private setServerFeeList(data: ServerFeeInfo[]): void {
        this._serverFeeList = data;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Action({ rawError: true })
    public async getServerFeeList(): Promise<void> {
        const response = await axios
            .get(GET_SERVER_FEE_LIST, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage("料金情報の取得に失敗しました。");
            return;
        }
        this.setServerFeeList(result.data);
    }
}

export const serverFeeStore = getModule(ServerFeeStore);
