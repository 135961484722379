import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { ProviderInfo } from "@/types/api/provider";
import {
    GET_PROVIDER_COMPANY_INDEX,
    POST_PROVIDER_REGISTER,
} from "@/configs/ApiConfig";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { authStore } from "../AuthStore";
import { ProviderForm } from "@/types/modules/provider-plan";

@Module({
    name: "provider",
    dynamic: true,
    store,
    namespaced: true,
})
class ProviderStore extends VuexModule {
    private _providerList: ProviderInfo[] = [];

    private _errorMessage = "";
    private _hasPostError = false;
    private _postError = "";

    public get providerList(): ProviderInfo[] {
        return this._providerList;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    public get hasPostError(): boolean {
        return this._hasPostError;
    }

    public get postError(): string {
        return this._postError;
    }

    @Mutation
    private setProviderList(data: ProviderInfo[]): void {
        this._providerList = data;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Mutation
    private setHasPostError(status: boolean): void {
        this._hasPostError = status;
    }

    @Mutation
    private setPostError(message: string): void {
        this._postError = message;
    }

    @Action({ rawError: true })
    public initError(): void {
        this.setErrorMessage("");
        this.setHasPostError(false);
        this.setPostError("");
    }

    @Action({ rawError: true })
    public async getProviderList(): Promise<void> {
        const response = await axios
            .get(GET_PROVIDER_COMPANY_INDEX, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);
        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setErrorMessage("");
        this.setProviderList(result.data);
        return;
    }

    @Action({ rawError: true })
    public async postProviderRegister(request: ProviderForm): Promise<void> {
        const response = await axios
            .post(POST_PROVIDER_REGISTER, request, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }
        const result = response.data;
        if (result.status === "error") {
            this.setHasPostError(true);
            this.setPostError(result.data.message);
            return;
        }
        this.setHasPostError(false);
        this.setPostError("");
    }
}

export const providerStore = getModule(ProviderStore);
