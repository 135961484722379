import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi",
    },
    theme: {
        themes: {
            light: {
                primary: "#945ccc",
                secondary: "#ff5722",
                accent: "#9c27b0",
                error: "#f44336",
                warning: "#e91e63",
                info: "#1976d2",
                success: "#ffeb3b",
            },
        },
    },
});
