
























































































































































import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { platformStore } from "@/models/store/Platform/PlatformStore";
import { PLATFORM_HEADER } from "@/libs/table-headers/Platform.header";
import { Platform } from "@/types/Platform/api/platform";
import PageBackButton from "@/components/parts/PageBackButton.vue";

@Component({
    components: {
        PageBackButton,
    },
})
export default class PlatformListComponent extends Vue {
    private headers: DataTableHeader[] = PLATFORM_HEADER;
    private search = "";
    private deleteDialog = false;

    public created(): void {
        platformStore.initStore();
        platformStore.getPlatformList();
    }

    private writeToClipboard(text: string): void {
        navigator.clipboard.writeText(text).catch((err) => console.error(err));
    }

    private editPlatform(platformId: number): void {
        platformStore.updateTargetId(platformId);
        this.$router.push({
            name: "PlatformForm",
            params: { type: "update" },
        });
    }

    private openDeleteConfirmDialog(platformId: number): void {
        this.deleteDialog = true;
        platformStore.updateTargetId(platformId);
    }

    private closeDialog(): void {
        platformStore.initStore();
        platformStore.updateTargetId(0);
        this.deleteDialog = false;
    }

    private async deletePlatform(): Promise<void> {
        await platformStore.deletePlatform();
        if (!platformStore.hasError) {
            this.deleteDialog = false;
            platformStore.initStore();
            platformStore.getPlatformList();
        }
    }

    public get platformList(): Platform[] {
        return platformStore.platformList;
    }

    public get hasError(): boolean {
        return platformStore.hasError;
    }

    public get errorMessage(): string {
        return platformStore.errorMessage;
    }
}
