import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { ServerForm } from "@/types/modules/server";
import {
    GET_SERVER,
    POST_REGISTER_SERVER,
    POST_UPDATE_SERVER,
} from "@/configs/ApiConfig";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { ServerInfo } from "@/types/api/server";
import { authStore } from "../AuthStore";
import { accountFormStore } from "./AccountFormStore";

@Module({
    name: "serverForm",
    dynamic: true,
    store,
    namespaced: true,
})
class ServerFormStore extends VuexModule {
    private _serverId = 0;
    private _server: ServerInfo | null = null;

    private _postStatus = false;

    private _fetchErrorMessage = "";
    private _postErrorMessage = "";

    public get serverId(): number {
        return this._serverId;
    }

    public get server(): ServerInfo | null {
        return this._server;
    }

    public get postStatus(): boolean {
        return this._postStatus;
    }

    public get fetchErrorMessage(): string {
        return this._fetchErrorMessage;
    }

    public get postErrorMessage(): string {
        return this._postErrorMessage;
    }

    @Mutation
    private setServerId(id: number): void {
        this._serverId = id;
    }

    @Mutation
    private setServer(data: ServerInfo | null): void {
        this._server = data;
    }

    @Mutation
    private setPostStatus(status: boolean): void {
        this._postStatus = status;
    }

    @Mutation
    private setFetchErrorMessage(message: string): void {
        this._fetchErrorMessage = message;
    }

    @Mutation
    private setPostErrorMessage(message: string): void {
        this._postErrorMessage = message;
    }

    @Action({ rawError: true })
    public refreshError(): void {
        this.setFetchErrorMessage("");
        this.setPostErrorMessage("");
        this.setPostStatus(false);
    }

    @Action({ rawError: true })
    public refreshId(): void {
        this.setServerId(0);
        accountFormStore.updateSshAccountId(0);
    }

    @Action({ rawError: true })
    public updateServerId(id: number): void {
        this.setServerId(id);
    }

    @Action({ rawError: true })
    public async getServer(serverId: number): Promise<void> {
        const response = await axios
            .get(`${GET_SERVER}/${serverId}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            this.setServer(null);
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            this.setServer(null);
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setServer(null);
            this.setFetchErrorMessage(result.data.message);
            return;
        }
        this.setServer(result.data);
        this.setServerId(result.data.serverId);
        accountFormStore.updateSshAccountId(result.data.ssh?.accountId ?? 0);
        this.setFetchErrorMessage("");
    }

    @Action({ rawError: true })
    public async postRegisterServer(request: ServerForm): Promise<void> {
        const response = await axios
            .post(POST_REGISTER_SERVER, request, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            this.refreshId();
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            this.refreshId();
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.refreshId();
            this.setPostErrorMessage(result.data.message);
            return;
        }
        this.setServerId(result.data.id);
        this.setPostStatus(true);
        this.setPostErrorMessage("");
    }

    @Action({ rawError: true })
    public async postUpdateServer(request: ServerForm): Promise<void> {
        const response = await axios
            .put(`${POST_UPDATE_SERVER}/${serverFormStore.serverId}`, request, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            this.refreshId();
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            this.refreshId();
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setPostErrorMessage(result.data.message);
            return;
        }
        this.setPostStatus(true);
        this.setPostErrorMessage("");
    }
}

export const serverFormStore = getModule(ServerFormStore);
