


























































































































import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import {
    PROVIDER_PLAN_HEADERS,
    STORAGE_PLAN_HEADERS,
} from "@/libs/table-headers/ProviderPlan.header";
import { ServerPlan, StoragePlan } from "@/types/api/provider";
import { providerPlanStore } from "@/models/store/ProviderPlans/ProviderPlanStore";

import PageBackButton from "@/components/parts/PageBackButton.vue";
import { PlanType, planTypeValues } from "@/types/modules/provider-plan";

@Component({
    components: {
        PageBackButton,
    },
})
export default class ProviderPlanListComponent extends Vue {
    private planTypeList = planTypeValues;
    private planType: PlanType = this.planTypeList[0];
    private search = "";
    private deleteDialog = false;
    private deletePlanId = 0;

    public created(): void {
        providerPlanStore.getServerPlanList();
        providerPlanStore.getStoragePlanList();
    }

    private editPlan(item_id: number): void {
        // String型でないとパラメータ箇所でエラーが出る為、idをnumber型→string型に変換
        const id = String(item_id);
        const destination = this.isServerPlan()
            ? "ServerPlanForm"
            : "StoragePlanForm";
        this.$router.push({
            name: destination,
            params: { id: id, formType: "update" },
        });
    }

    private openDeleteConfirmDialog(id: number): void {
        providerPlanStore.initStore();
        this.deleteDialog = true;
        this.deletePlanId = id;
    }

    private async deletePlan(): Promise<void> {
        if (this.isServerPlan()) {
            await providerPlanStore.deleteServerPlan(this.deletePlanId);
        } else {
            await providerPlanStore.deleteStoragePlan(this.deletePlanId);
        }

        if (providerPlanStore.deleteStatus) {
            this.deleteDialog = false;
            providerPlanStore.getServerPlanList();
            providerPlanStore.getStoragePlanList();
        }
    }

    private closeDialog(): void {
        this.deleteDialog = false;
        this.deletePlanId = 0;
    }

    public get serverPlanError(): string {
        return providerPlanStore.fetchServerListErrorMessage;
    }

    public get storagePlanError(): string {
        return providerPlanStore.fetchStorageListErrorMessage;
    }

    public get deleteErrorMessage(): string {
        return providerPlanStore.deleteErrorMessage;
    }

    private isServerPlan(): boolean {
        return this.planType === "サーバプラン";
    }

    public get headers(): DataTableHeader[] {
        return this.isServerPlan()
            ? PROVIDER_PLAN_HEADERS
            : STORAGE_PLAN_HEADERS;
    }

    public get planList(): (ServerPlan | StoragePlan)[] {
        return this.isServerPlan()
            ? providerPlanStore.serverPlanList
            : providerPlanStore.storagePlanList;
    }
}
