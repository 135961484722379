import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { OperationType, PermissionForm } from "@/types/modules/permission";
import {
    DELETE_EMPLOYEE_MEMBERSHIP,
    POST_GROUP_PERMISSION_UPDATE,
    POST_REGISTER_EMPLOYEE_MEMBERSHIP,
} from "@/configs/ApiConfig";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { MembershipEmployeeRequest } from "@/types/api/permission";
import { authStore } from "../AuthStore";

@Module({
    name: "groupPermissionUpdate",
    dynamic: true,
    store,
    namespaced: true,
})
/**
 * グループ権限区分更新ストア
 */
class GroupPermissionUpdateStore extends VuexModule {
    private _operationType: OperationType = "permission";
    private _successMessage = "";
    private _errorMessage = "";

    public get operationType(): OperationType {
        return this._operationType;
    }

    public get successMessage(): string {
        return this._successMessage;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    @Mutation
    private setOperationType(type: OperationType): void {
        this._operationType = type;
    }

    @Mutation
    private setSuccessMessage(message: string): void {
        this._successMessage = message;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Action({ rawError: true })
    public initMessage(): void {
        this.setOperationType("permission");
        this.setSuccessMessage("");
        this.setErrorMessage("");
    }

    @Action({ rawError: true })
    public async postUpdateGroupPermission(
        request: PermissionForm
    ): Promise<void> {
        const response = await axios
            .post(
                `${POST_GROUP_PERMISSION_UPDATE}/${request.groupId}`,
                request,
                {
                    headers: {
                        Authorization: `Bearer ${authStore.token}`,
                    },
                }
            )
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setOperationType("permission");
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setOperationType("permission");
        this.setSuccessMessage("データを更新しました。");
        return;
    }

    @Action({ rawError: true })
    public async registerMemberships(
        request: MembershipEmployeeRequest
    ): Promise<void> {
        const response = await axios
            .post(
                `${POST_REGISTER_EMPLOYEE_MEMBERSHIP}/${request.targetGroupId}`,
                request.employee,
                {
                    headers: {
                        Authorization: `Bearer ${authStore.token}`,
                    },
                }
            )
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setOperationType("membership");
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setOperationType("membership");
        this.setSuccessMessage("従業員の追加に成功しました。");
        return;
    }

    @Action({ rawError: true })
    public async deleteMemberships(
        request: MembershipEmployeeRequest
    ): Promise<void> {
        const response = await axios
            .delete(`${DELETE_EMPLOYEE_MEMBERSHIP}/${request.targetGroupId}`, {
                data: request.employee,
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);
        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setOperationType("membership");
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setOperationType("membership");
        this.setSuccessMessage("従業員の削除に成功しました。");
        return;
    }
}

export const groupPermissionUpdateStore = getModule(GroupPermissionUpdateStore);
