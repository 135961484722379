import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { MembershipEmployee, PermissionGroup } from "@/types/api/permission";
import {
    GET_NONMEMBERSHIP_GROUP_EMPLOYEE,
    GET_PERMISSION_GROUP,
    POST_PERMISSION_GROUP_REGISTER,
} from "@/configs/ApiConfig";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { authStore } from "../AuthStore";
import { PermissionGroupForm } from "@/types/modules/permission";

@Module({
    name: "permissionGroupForm",
    dynamic: true,
    store,
    namespaced: true,
})
class PermissionGroupFormStore extends VuexModule {
    private _groupPermissionForm: PermissionGroup = {
        groupId: 0,
        groupName: "",
        systemName: "",
        accountType: "管理者",
        members: [],
        permissionList: {
            show: false,
            add: false,
            edit: false,
            delete: false,
        },
    };

    private _membershipEmployees: MembershipEmployee[] = [];
    private _nonMembershipEmployees: MembershipEmployee[] = [];

    private _fetchErrorMessage = "";
    private _postErrorMessage = "";
    private _postStatus = false;

    public get groupPermissionForm(): PermissionGroup {
        return this._groupPermissionForm;
    }

    public get membershipEmployees(): MembershipEmployee[] {
        return this._membershipEmployees;
    }

    public get nonMembershipEmployees(): MembershipEmployee[] {
        return this._nonMembershipEmployees;
    }

    public get fetchErrorMessage(): string {
        return this._fetchErrorMessage;
    }

    public get postErrorMessage(): string {
        return this._postErrorMessage;
    }

    public get postStatus(): boolean {
        return this._postStatus;
    }

    @Mutation
    private setGroupPermissionForm(data: PermissionGroup): void {
        this._groupPermissionForm = data;
    }

    @Mutation
    private setMembershipEmployees(data: MembershipEmployee[]): void {
        this._membershipEmployees = data;
    }

    @Mutation
    private setNonMembershipEmployees(data: MembershipEmployee[]): void {
        this._nonMembershipEmployees = data;
    }

    @Mutation
    private setFetchErrorMessage(message: string): void {
        this._fetchErrorMessage = message;
    }

    @Mutation
    private setPostErrorMessage(message: string): void {
        this._postErrorMessage = message;
    }

    @Mutation
    private setPostStatus(status: boolean): void {
        this._postStatus = status;
    }

    @Action({ rawError: true })
    public refreshError(): void {
        this.setPostErrorMessage("");
        this.setPostStatus(false);
    }

    @Action({ rawError: true })
    public async postRegisterPermissionGroup(
        request: PermissionGroupForm
    ): Promise<void> {
        const response = await axios
            .post(POST_PERMISSION_GROUP_REGISTER, request, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setPostErrorMessage(result.data.message);
            return;
        }
        this.setPostStatus(true);
        this.setPostErrorMessage("");
    }

    @Action({ rawError: true })
    public async getPermissionGroupById(groupId: number): Promise<void> {
        const response = await axios
            .get(`${GET_PERMISSION_GROUP}/${groupId}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setFetchErrorMessage(result.data.message);
            return;
        }
        this.setFetchErrorMessage("");
        this.setGroupPermissionForm(result.data);
        this.extractMembershipEmployeesFromResponse(result.data);
    }

    @Action({ rawError: true })
    private extractMembershipEmployeesFromResponse(
        group: PermissionGroup
    ): void {
        if (!group.members) return;
        const membershipEmployees = group.members.map((member) => {
            const groupInfo = [
                {
                    groupName: group.groupName,
                    systemName: group.systemName,
                    accountType: group.accountType,
                },
            ];
            return {
                employeeId: member.employeeId,
                employeeName: member.employeeName,
                group: groupInfo,
            };
        });
        this.setMembershipEmployees(membershipEmployees);
    }

    @Action({ rawError: true })
    public async getNonMembershipEmployees(groupId: number): Promise<void> {
        const response = await axios
            .get(`${GET_NONMEMBERSHIP_GROUP_EMPLOYEE}/${groupId}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setFetchErrorMessage(result.data.message);
        }
        this.setFetchErrorMessage("");
        this.setNonMembershipEmployees(result.data);
    }
}
export const permissionGroupFormStore = getModule(PermissionGroupFormStore);
