






















































import { Component, Vue } from "vue-property-decorator";
import { LoginInfo } from "@/types/modules/auth-type";
import { authStore } from "@/models/store/AuthStore";

@Component
export default class LoginComponent extends Vue {
    private loginInfo: LoginInfo = {
        loginId: "",
        password: "",
    };
    private showPassword = false;
    // フォームバリデーション関連
    private valid = true;
    private rules = {
        loginId: [(v: string) => !!v || "ログインIDを入力してください"],
        password: [(v: string) => !!v || "パスワードを入力してください"],
    };

    public async created(): Promise<void> {
        // NOTE: 以前のデータを残さないようlocalStrageを初期化する
        await authStore.initLocalStorage();
    }

    private onKeyPressEntor(): void {
        if (!this.loginInfo.loginId || !this.loginInfo.password) return;
        this.submit();
    }

    private async submit(): Promise<void> {
        await authStore.login(this.loginInfo);
        await this.$nextTick();
        if (!authStore.isAuthenticated) return;
        this.$router.push("/server/list");
    }

    private get errorMessage(): string {
        return authStore.authErrorMessage;
    }
}
