var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('page-back-button'),_c('div',{staticClass:"page-container"},[_c('h1',[_vm._v("サーバ操作権限グループの編集")]),(_vm.fetchErrorMessage)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.fetchErrorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"content-container"},[_c('div',[_c('ul',[_c('li',[_vm._v(" 対象システムを変更する場合は新規グループを作成してください。 ")]),_c('li',[_vm._v(" アカウント区分の変更をする場合は新規グループを作成してください。 ")]),_c('li',[_vm._v(" 同じシステムのグループに同一の従業員を複数登録する事はできません。 ")])])]),_c('div',{staticClass:"permission-group-info-container"},[_c('v-text-field',{attrs:{"label":"グループ名","value":_vm.groupPermission.groupName,"readonly":"","filled":"","dense":""}}),_c('div',{staticClass:"permission-target-info-line"},[_c('v-text-field',{attrs:{"label":"システム名","value":_vm.groupPermission.systemName,"readonly":"","filled":"","dense":""}}),_c('v-text-field',{attrs:{"label":"アカウント区分","value":_vm.groupPermission.accountType,"readonly":"","filled":"","dense":""}})],1)],1),_c('div',{attrs:{"permission-edit-container":""}},[_c('div',{staticClass:"permission-list"},[_c('v-card',{staticClass:"pa-2 grey lighten-5"},[_c('v-card-text',[_c('h3',{staticClass:"card-header"},[_vm._v(" グループに付与する権限を更新 ")]),(
                                    _vm.operationType === 'permission' &&
                                    _vm.successMessage
                                )?_c('p',{staticClass:"success-message"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e(),(
                                    _vm.operationType === 'permission' &&
                                    _vm.errorMessage
                                )?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('v-form',{staticClass:"permission-form"},[_c('v-checkbox',{attrs:{"label":"閲覧","dense":""},model:{value:(
                                        _vm.permissionForm.permissionList.show
                                    ),callback:function ($$v) {_vm.$set(_vm.permissionForm.permissionList, "show", $$v)},expression:"\n                                        permissionForm.permissionList.show\n                                    "}}),_c('v-checkbox',{attrs:{"label":"追加","dense":""},model:{value:(
                                        _vm.permissionForm.permissionList.add
                                    ),callback:function ($$v) {_vm.$set(_vm.permissionForm.permissionList, "add", $$v)},expression:"\n                                        permissionForm.permissionList.add\n                                    "}}),_c('v-checkbox',{attrs:{"label":"編集","dense":""},model:{value:(
                                        _vm.permissionForm.permissionList.edit
                                    ),callback:function ($$v) {_vm.$set(_vm.permissionForm.permissionList, "edit", $$v)},expression:"\n                                        permissionForm.permissionList.edit\n                                    "}}),_c('v-checkbox',{attrs:{"label":"削除","dense":""},model:{value:(
                                        _vm.permissionForm.permissionList.delete
                                    ),callback:function ($$v) {_vm.$set(_vm.permissionForm.permissionList, "delete", $$v)},expression:"\n                                        permissionForm.permissionList.delete\n                                    "}}),_c('v-btn',{staticClass:"permission-update-button",attrs:{"color":"primary"},on:{"click":_vm.updateGroupPermission}},[_vm._v("権限を更新")])],1)],1)],1)],1),(_vm.operationType === 'membership' && _vm.successMessage)?_c('p',{staticClass:"success-message"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e(),(_vm.operationType === 'membership' && _vm.errorMessage)?_c('p',{staticClass:"success-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"member-list"},[_c('v-card',{staticClass:"pa-2 grey lighten-5 membership-card"},[_c('v-card-text',[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v(" "+_vm._s(_vm.groupPermission.groupName)+"に所属中のメンバー ")]),_c('v-btn',{attrs:{"color":"warning","disabled":_vm.membershipEmployeeSelect.length ===
                                        0},on:{"click":_vm.removeEmployee}},[_vm._v("一括削除")])],1),_c('div',{staticClass:"search-container"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"従業員名","single-line":"","hide-detail":"","clearable":""},model:{value:(_vm.membershipEmployeeSearch),callback:function ($$v) {_vm.membershipEmployeeSearch=$$v},expression:"membershipEmployeeSearch"}})],1),_c('v-data-table',{attrs:{"headers":_vm.membershipEmployeeHeader,"items":_vm.membershipEmployees,"items-per-page":1000,"item-key":"employeeId","show-select":"","search":_vm.membershipEmployeeSearch,"hide-default-footer":""},model:{value:(_vm.membershipEmployeeSelect),callback:function ($$v) {_vm.membershipEmployeeSelect=$$v},expression:"membershipEmployeeSelect"}})],1)],1),_c('v-card',{staticClass:"pa-2 membership-card grey lighten-5"},[_c('v-card-text',[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v(" "+_vm._s(_vm.groupPermission.groupName)+"に未所属のメンバー ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.nonMembershipEmployeeSelect.length ===
                                        0},on:{"click":_vm.addEmployee}},[_vm._v("一括追加")])],1),_c('div',{staticClass:"search-container"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"従業員名","single-line":"","hide-detail":"","clearable":""},model:{value:(_vm.nonMembershipEmployeeSearch),callback:function ($$v) {_vm.nonMembershipEmployeeSearch=$$v},expression:"nonMembershipEmployeeSearch"}})],1),_c('v-data-table',{attrs:{"headers":_vm.nonMembershipEmployeeHeader,"items-per-page":1000,"items":_vm.nonMembershipEmployees,"item-key":"employeeId","show-select":"","search":_vm.nonMembershipEmployeeSearch,"hide-default-footer":""},model:{value:(_vm.nonMembershipEmployeeSelect),callback:function ($$v) {_vm.nonMembershipEmployeeSelect=$$v},expression:"nonMembershipEmployeeSelect"}})],1)],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }