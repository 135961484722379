


























































































































































































import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import {
    PROXY_SERVER_INFO_HEADER,
    SERVER_INFO_HEADER,
    WHITE_LIST_SERVER_INFO_HEADER,
} from "@/libs/table-headers/Server.header";

import { ServerInfo, SshInfo } from "@/types/api/server";
import PageBackButton from "@/components/parts/PageBackButton.vue";
import { serverListStore } from "@/models/store/Servers/ServerListStore";
import {
    ContractStatus,
    contractStatusValues,
    ServerIdInfo,
} from "@/types/modules/server";
import { serverFormStore } from "@/models/store/Servers/ServerFormStore";
import { accountFormStore } from "@/models/store/Servers/AccountFormStore";
import { belongsPermissionGroupStore } from "@/models/store/Employees/BelongsPermissionGroupStore";

@Component({
    components: {
        PageBackButton,
    },
})
export default class ServerListComponent extends Vue {
    private search = "";
    private tableTypeList = [
        "サーバーリスト",
        "プロキシサーバーリスト",
        "ホワイトリスト登録サーバーリスト",
    ];
    private contractStatusList = contractStatusValues;
    private deleteDialog = false;

    private listType = this.tableTypeList[0];
    private contractStatus: ContractStatus = this.contractStatusList[0];

    public async created(): Promise<void> {
        await belongsPermissionGroupStore.getBelongsPermissionGroupIndex();
        await serverListStore.getServerList();
    }

    public get serverList(): ServerInfo[] {
        return serverListStore.serverList;
    }

    public get displayServerList(): ServerInfo[] {
        return this.serverList.filter((server: ServerInfo) => {
            if (this.listType === this.tableTypeList[1]) {
                return (
                    server.contractStatus === this.contractStatus &&
                    server.proxyInfo
                );
            }
            if (this.listType === this.tableTypeList[2]) {
                return (
                    server.contractStatus === this.contractStatus &&
                    server.whiteServerInfo
                );
            }
            return server.contractStatus === this.contractStatus;
        });
    }

    public get headers(): DataTableHeader[] {
        if (this.listType === this.tableTypeList[1]) {
            return PROXY_SERVER_INFO_HEADER;
        }
        if (this.listType === this.tableTypeList[2]) {
            return WHITE_LIST_SERVER_INFO_HEADER;
        }
        return SERVER_INFO_HEADER;
    }

    private displayBastionHost(needsBastionHost: boolean) {
        return needsBastionHost ? "○" : "ー";
    }

    private editServerInfo(serverId: number): void {
        serverFormStore.updateServerId(serverId);
        this.$router.push({
            name: "ServerForm",
            params: { formType: "update" },
        });
    }

    private openDeleteDialog(serverId: number, sshInfo?: SshInfo): void {
        this.deleteDialog = true;
        serverFormStore.updateServerId(serverId);

        console.log(sshInfo?.accountId);
        sshInfo !== undefined
            ? accountFormStore.updateSshAccountId(sshInfo.accountId)
            : accountFormStore.updateSshAccountId(0);
    }

    private async deleteServerInfo(): Promise<void> {
        const idParams: ServerIdInfo = {
            serverId: serverFormStore.serverId,
            sshAccountId: accountFormStore.sshAccountId,
        };
        await serverListStore.postServerAndAccountDelete(idParams);
        if (serverListStore.deleteStatus) {
            serverFormStore.refreshError();
            serverListStore.getServerList();
            this.deleteDialog = false;
        }
    }

    private closeDeleteDialog(): void {
        serverFormStore.refreshId();
        this.deleteDialog = false;
    }

    public get fetchErrorMessage(): string {
        return serverListStore.fetchErrorMessage;
    }

    public get deleteErrorMessage(): string {
        return serverListStore.deleteErrorMessage;
    }

    private writeToClipboard(text: string): void {
        navigator.clipboard.writeText(text).catch((e) => {
            console.error(e);
        });
    }
}
