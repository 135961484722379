import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authStore } from "@/models/store/AuthStore";

import Login from "@/components/pages/Auth/Login.vue";
import ServiceAccountList from "@/components/pages/ServiceAccount/ServiceAccountList.vue";
import ServiceAccountForm from "@/components/pages/ServiceAccount/ServiceAccountForm.vue";
import ProviderPlanListComponent from "@/components/pages/ProviderPlan/ProviderPlanList.vue";
import ServerPlanFormComponent from "@/components/pages/ProviderPlan/ServerPlanForm.vue";
import StoragePlanFormComponent from "@/components/pages/ProviderPlan/StoragePlanForm.vue";
import ServerList from "@/components/pages/Server/ServerList.vue";
import ServerForm from "@/components/pages/Server/ServerForm.vue";
import ServerAccountForm from "@/components/pages/Server/ServerAccountForm.vue";
import FeeList from "@/components/pages/Fee/FeeList.vue";
import PermissionList from "@/components/pages/Permission/PermissionList.vue";
import PermissionForm from "@/components/pages/Permission/PermissionForm.vue";
import SystemError from "@/components/pages/Error/SystemError.vue";
import ServicePermission from "@/components/pages/ServiceAccount/ServicePermission.vue";
import PlatformList from "@/components/pages/Platform/PlatformList.vue";
import PlatformForm from "@/components/pages/Platform/PlatformForm.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/server/list",
        name: "ServerList",
        component: ServerList,
    },
    {
        path: "/server/base/operation",
        name: "ServerForm",
        component: ServerForm,
        props: true,
    },
    {
        path: "/server/account/operation/",
        name: "ServerAccountForm",
        component: ServerAccountForm,
        props: true,
    },
    {
        path: "/provider-plan/list",
        name: "ProviderPlanList",
        component: ProviderPlanListComponent,
    },
    {
        path: "/provider-plan/storage",
        name: "StoragePlanForm",
        component: StoragePlanFormComponent,
        props: true,
    },
    {
        path: "/provider-plan/server",
        name: "ServerPlanForm",
        component: ServerPlanFormComponent,
        props: true,
    },
    {
        path: "/fee/list",
        name: "FeeList",
        component: FeeList,
    },
    {
        path: "/server-permission/list",
        name: "PermissionList",
        component: PermissionList,
    },
    {
        path: "/permission/update/:id",
        name: "PermissionForm",
        component: PermissionForm,
    },
    {
        path: "/platform/list",
        name: "PlatformList",
        component: PlatformList,
    },
    {
        path: "/platform/form",
        name: "PlatformForm",
        component: PlatformForm,
        props: true,
    },
    {
        path: "/service-account/list",
        name: "ServiceAccountList",
        component: ServiceAccountList,
    },
    {
        path: "/service-account/operation",
        name: "ServiceAccountForm",
        component: ServiceAccountForm,
        props: true,
    },
    {
        path: "/service-permission",
        name: "ServicePermission",
        component: ServicePermission,
    },

    {
        path: "/error",
        name: "Error",
        component: SystemError,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        // NOTE: 表示位置は一旦最上部に固定
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    if (to.name === "Error") {
        next();
        return;
    }
    if (to.name !== "Login" && !authStore.isAuthenticated) {
        next({ path: "/" });
    } else if (to.name === "Login" && authStore.isAuthenticated) {
        next({ path: from.path });
    }
    next();
});

export default router;
