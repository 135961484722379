export interface serverPlanForm {
    serverPlanId: number;
    params: {
        providerName: string;
        zone: string;
        instanceName: string;
        core: number;
        ram: number;
        baseFee: number;
        ipAddressFee: number;
    };
}

export interface storagePlanForm {
    storagePlanId: number;
    params: {
        providerName: string;
        planName: string;
        capacity: number;
        fee: number;
    };
}

export interface ProviderForm {
    providerName: string;
}

export const planTypeValues = ["サーバプラン", "ストレージプラン"] as const;
export type PlanTypeTuple = typeof planTypeValues;
export type PlanType = PlanTypeTuple[number];
