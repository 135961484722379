import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { GET_CATEGORY_LIST, POST_CATEGORY_REGISTER } from "@/configs/ApiConfig";
import { CategoryForm, CategoryTypes } from "@/types/modules/server";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { CategoryInfo } from "@/types/api/server";
import { authStore } from "../AuthStore";

@Module({
    name: "category",
    dynamic: true,
    store,
    namespaced: true,
})
class CategoryStore extends VuexModule {
    private _largeCategory: CategoryInfo[] = [];
    private _smallCategory: CategoryInfo[] = [];

    private _errorMessage = "";

    public get largeCategory(): CategoryInfo[] {
        return this._largeCategory;
    }

    public get smallCategory(): CategoryInfo[] {
        return this._smallCategory;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    @Mutation
    private setLargeCategory(data: CategoryInfo[]): void {
        this._largeCategory = data;
    }

    @Mutation
    private setSmallCategory(data: CategoryInfo[]): void {
        this._smallCategory = data;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Action({ rawError: true })
    public initError(): void {
        this.setErrorMessage("");
    }

    @Action({ rawError: true })
    public async getCategoryList(type: CategoryTypes): Promise<void> {
        const response = await axios
            .get(GET_CATEGORY_LIST + `/${type}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return;
        }
        this.setErrorMessage("");
        type === "large"
            ? this.setLargeCategory(result.data)
            : this.setSmallCategory(result.data);
        return;
    }

    @Action({ rawError: true })
    public async postRegisterCategory(request: CategoryForm): Promise<boolean> {
        const response = await axios
            .post(POST_CATEGORY_REGISTER, request, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return false;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return false;
        }

        const result = response.data;

        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return false;
        }
        this.setErrorMessage("");
        return true;
    }
}

export const categoryStore = getModule(CategoryStore);
