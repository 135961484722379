import { FormTypes } from "@/types/Common/common-type";

export default class FormType {
    private readonly _formType: FormTypes = "new";

    constructor(formType: FormTypes) {
        this._formType = formType;
    }

    public isRegisterPage(): boolean {
        return this._formType === "new";
    }
}
