export interface PermissionGroupForm {
    groupName: string;
    systemName: string;
    accountType: AccountType;
    permissionList: {
        show: boolean;
        add: boolean;
        edit: boolean;
        delete: boolean;
    };
}

export interface PermissionForm {
    groupId: number;
    permissionList: {
        show?: boolean;
        add?: boolean;
        edit?: boolean;
        delete?: boolean;
    };
}

export const accountTypeValues = ["管理者", "一般ユーザー"] as const;
export type AccountTypeTuple = typeof accountTypeValues;
export type AccountType = AccountTypeTuple[number];

export type OperationType = "permission" | "membership";
