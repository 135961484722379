





















































import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import {
    SERVICE_PERMISSION_HEADER,
    EMPLOYEE_PERMISSION_HEADER,
} from "@/libs/table-headers/ServiceAccount.header";

import { ServicePermissionInfo } from "@/types/api/service-account";
import { serviceAccountStore } from "@/models/store/ServiceAccounts/ServiceAccountStore";
import { servicePermissionStore } from "@/models/store/ServiceAccounts/ServicePermissionStore";

import PageBackButton from "@/components/parts/PageBackButton.vue";

import { SelectionType } from "@/types/api/permission";

import DataTable from "@/components/parts/PermissionDataTable.vue";

@Component({
    components: {
        PageBackButton,
        DataTable,
    },
})
export default class ServicePermissionComponent extends Vue {
    private selected = 1;
    private readonly selectionType: SelectionType[] = [
        { label: "従業員単位", value: "employee" },
        { label: "サービス単位", value: "service" },
    ];
    private radioGroup = this.selectionType[0].value;

    public created(): void {
        servicePermissionStore.initStore();
        servicePermissionStore.getServicePermissionList();
        servicePermissionStore.getEmployeePermissionList();
    }

    public get servicePermissionList(): ServicePermissionInfo[] {
        if (this.radioGroup === this.selectionType[0].value) {
            return servicePermissionStore.servicePermissionList;
        }
        return servicePermissionStore.employeePermissionList;
    }

    public get selectList(): ServicePermissionInfo[] {
        return servicePermissionStore.servicePermissionList;
    }

    public get headers(): DataTableHeader[] {
        if (this.radioGroup === this.selectionType[0].value) {
            return SERVICE_PERMISSION_HEADER;
        }
        return EMPLOYEE_PERMISSION_HEADER;
    }

    public get itemLabel(): string {
        if (this.radioGroup === this.selectionType[0].value) {
            return "従業員";
        }
        return "サービス";
    }

    private savePermission(id: number): void {
        console.log(id + "を保存");
    }

    public get errorMessage(): string {
        return serviceAccountStore.errorMessage;
    }
}
