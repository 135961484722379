// 各テーブルの設定
import { DataTableHeader } from "vuetify";

export const PROVIDER_PLAN_HEADERS: DataTableHeader[] = [
    {
        text: "プロバイダ名",
        align: "center",
        value: "providerName",
        width: "180",
    },
    {
        text: "ゾーン名",
        align: "center",
        value: "zone",
        width: "200",
        filterable: false,
    },
    {
        text: "インスタンス名",
        align: "center",
        value: "instanceName",
        width: "200",
        filterable: false,
    },
    {
        text: "コア",
        align: "center",
        value: "core",
        width: "120",
        filterable: false,
    },
    {
        text: "RAM(GB)",
        align: "center",
        value: "ram",
        width: "120",
        filterable: false,
    },
    {
        text: "基本料金",
        align: "center",
        value: "baseFee",
        width: "180",
        filterable: false,
    },
    {
        text: "IPアドレス料金",
        align: "center",
        value: "ipAddressFee",
        width: "180",
        filterable: false,
    },
    {
        text: "操作",
        align: "center",
        value: "action",
        width: "150",
        filterable: false,
    },
];

export const STORAGE_PLAN_HEADERS: DataTableHeader[] = [
    {
        text: "プロバイダ名",
        align: "center",
        value: "providerName",
        width: "180",
    },
    {
        text: "プラン名",
        align: "center",
        value: "planName",
        width: "200",
        filterable: false,
    },
    {
        text: "ストレージ容量(GB)",
        align: "center",
        value: "capacity",
        width: "180",
        filterable: false,
    },
    {
        text: "月額料金",
        align: "center",
        value: "fee",
        width: "200",
        filterable: false,
    },
    {
        text: "操作",
        align: "center",
        value: "action",
        width: "150",
        filterable: false,
    },
];
