













































































import { Component, Prop, Vue } from "vue-property-decorator";

import AddProviderDialog from "@/components/parts/dialog/ProviderPlan/AddProviderDialog.vue";
import PageBackButton from "@/components/parts/PageBackButton.vue";

import { FormTypes } from "@/types/modules/common";
import FormTypeClass from "@/models/class/form-type";

import { providerStore } from "@/models/store/ProviderPlans/ProviderStore";
import { ProviderCompany } from "@/types/api/provider";
import { storagePlanForm } from "@/types/modules/provider-plan";
import { providerPlanStore } from "@/models/store/ProviderPlans/ProviderPlanStore";

@Component({
    components: {
        AddProviderDialog,
        PageBackButton,
    },
})
export default class StoragePlanFormComponent extends Vue {
    @Prop({ type: String, default: null }) readonly id!: string | null;
    @Prop({ type: String, default: "new" })
    private readonly formType!: FormTypes;

    private storagePlanForm: storagePlanForm = {
        storagePlanId: 0,
        params: {
            providerName: "",
            planName: "",
            capacity: 0,
            fee: 0,
        },
    };

    private formTypeClass = new FormTypeClass(this.formType);

    private dialog = false;

    // フォームバリデーション
    private valid = true;
    private rules = {
        providerName: [(v: string) => !!v || "プロバイダ名を選択してください"],
        planName: [(v: string) => !!v || "プラン名を入力してください"],
        capacity: [
            (v: number) =>
                v >= 0 || "ストレージ容量は0以上の数値で入力してください",
            (v: number) => v === 0 || !!v || "ストレージ容量を入力してください",
        ],
        fee: [
            (v: number) =>
                v >= 0 || "ストレージ料金は0円以上で入力してください",
            (v: number) => v === 0 || !!v || "ストレージ料金を入力してください",
        ],
    };

    public created(): void {
        providerPlanStore.initStore();
        providerStore.getProviderCompanyList();
        this.formTypeClass = new FormTypeClass(this.formType);
        if (this.formTypeClass.isRegisterPage()) return;
        // パラメータでstring型でidが来るのでnumber型に変換
        const targetId = Number(this.id);
        this.updateFormSetting(targetId);
    }

    private async updateFormSetting(targetId: number): Promise<void> {
        await providerPlanStore.getStoragePlan(targetId);
        const storagePlan = providerPlanStore.storagePlan;

        this.storagePlanForm.storagePlanId = storagePlan.planId;
        this.storagePlanForm.params.providerName = storagePlan.providerName;
        this.storagePlanForm.params.planName = storagePlan.planName ?? "";
        this.storagePlanForm.params.capacity = storagePlan.capacity ?? 0;
        this.storagePlanForm.params.fee = storagePlan.fee ?? 0;
    }

    private async submit(): Promise<void> {
        if (this.formTypeClass.isRegisterPage()) {
            await providerPlanStore.postRegisterStoragePlan(
                this.storagePlanForm
            );
        } else {
            await providerPlanStore.putUpdateStoragePlan(this.storagePlanForm);
        }

        if (providerPlanStore.postStatus) {
            this.$router.push("/provider-plan/list");
        }
    }

    public get displayTitle(): string {
        return this.formTypeClass.isRegisterPage()
            ? "ストレージプランの作成"
            : "ストレージプランの編集";
    }

    public get displayButtonMessage(): string {
        return this.formTypeClass.isRegisterPage()
            ? "プランを作成"
            : "プランを更新";
    }

    public get providerCompanyList(): ProviderCompany[] {
        return providerStore.providerCompanyList;
    }

    public get errorMessage(): string {
        return providerPlanStore.postErrorMessage;
    }

    private closeDialog(dialogFlag: boolean): void {
        this.dialog = dialogFlag;
    }
}
