


















import { Component, Vue } from "vue-property-decorator";
import { errorStore } from "@/models/store/ErrorStore";

@Component
export default class SystemErrorComponent extends Vue {
    public get errorMessage(): string {
        return errorStore.message;
    }
}
