import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "../ErrorStore";
import { ServerPlan, StoragePlan } from "@/types/api/provider";
import {
    DELETE_SERVER_PLAN,
    DELETE_STORAGE_PLAN,
    GET_SERVER_PLAN,
    GET_SERVER_PLAN_INDEX,
    GET_STORAGE_PLAN,
    GET_STORAGE_PLAN_INDEX,
    POST_SERVER_PLAN_REGISTER,
    POST_SERVER_PLAN_UPDATE,
    POST_STORAGE_PLAN_REGISTER,
    POST_STORAGE_PLAN_UPDATE,
} from "@/configs/ApiConfig";
import {
    INTERNAL_SERVER_ERROR,
    UNAUTHORIZED_ERROR,
    VALIDATION_ERROR,
} from "@/libs/util";
import { authStore } from "../AuthStore";
import { serverPlanForm, storagePlanForm } from "@/types/modules/provider-plan";

@Module({
    name: "providerPlan",
    dynamic: true,
    store,
    namespaced: true,
})
class ProviderPlanStore extends VuexModule {
    private _serverPlanList: ServerPlan[] = [];
    private _storagePlanList: StoragePlan[] = [];

    private _serverPlan!: ServerPlan;
    private _storagePlan!: StoragePlan;

    private _fetchServerPlanErrorMessage = "";
    private _fetchStoragePlanErrorMessage = "";
    private _postErrorMessage = "";
    private _deleteErrorMessage = "";

    private _postStatus = false;
    private _deleteStatus = false;

    public get serverPlanList(): ServerPlan[] {
        return this._serverPlanList;
    }

    public get storagePlanList(): StoragePlan[] {
        return this._storagePlanList;
    }

    public get serverPlan(): ServerPlan {
        return this._serverPlan;
    }

    public get storagePlan(): StoragePlan {
        return this._storagePlan;
    }

    public get fetchServerListErrorMessage(): string {
        return this._fetchServerPlanErrorMessage;
    }

    public get fetchStorageListErrorMessage(): string {
        return this._fetchStoragePlanErrorMessage;
    }

    public get postErrorMessage(): string {
        return this._postErrorMessage;
    }

    public get deleteErrorMessage(): string {
        return this._deleteErrorMessage;
    }

    public get postStatus(): boolean {
        return this._postStatus;
    }

    public get deleteStatus(): boolean {
        return this._deleteStatus;
    }

    @Mutation
    private setServerPlanList(data: ServerPlan[]): void {
        this._serverPlanList = data;
    }

    @Mutation
    private setStoragePlanList(data: StoragePlan[]): void {
        this._storagePlanList = data;
    }

    @Mutation
    private setServerPlan(data: ServerPlan): void {
        this._serverPlan = data;
    }

    @Mutation
    private setStoragePlan(data: StoragePlan): void {
        this._storagePlan = data;
    }

    @Mutation
    private setFetchServerPlanErrorMessage(message: string): void {
        this._fetchServerPlanErrorMessage = message;
    }

    @Mutation
    private setFetchStoragePlanErrorMessage(message: string): void {
        this._fetchStoragePlanErrorMessage = message;
    }

    @Mutation
    private setPostErrorMessage(message: string): void {
        this._postErrorMessage = message;
    }

    @Mutation
    private setDeleteErrorMessage(message: string): void {
        this._deleteErrorMessage = message;
    }

    @Mutation
    private setPostStatus(status: boolean): void {
        this._postStatus = status;
    }

    @Mutation
    private setDeleteStatus(status: boolean): void {
        this._deleteStatus = status;
    }

    @Action({ rawError: true })
    public initStore(): void {
        this.setFetchServerPlanErrorMessage("");
        this.setFetchStoragePlanErrorMessage("");
        this.setPostErrorMessage("");
        this.setDeleteErrorMessage("");
        this.setPostStatus(false);
        this.setDeleteStatus(false);
    }

    @Action({ rawError: true })
    public async getServerPlanList(): Promise<void> {
        const response = await axios
            .get(GET_SERVER_PLAN_INDEX, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setFetchServerPlanErrorMessage(result.data.message);
            return;
        }
        this.setServerPlanList(result.data);
        this.setFetchServerPlanErrorMessage("");
    }

    @Action({ rawError: true })
    public async getStoragePlanList(): Promise<void> {
        const response = await axios
            .get(GET_STORAGE_PLAN_INDEX, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setFetchStoragePlanErrorMessage(result.data.message);
            return;
        }
        this.setStoragePlanList(result.data);
        this.setFetchStoragePlanErrorMessage("");
    }

    @Action({ rawError: true })
    public async getServerPlan(serverPlanId: number): Promise<void> {
        const response = await axios
            .get(`${GET_SERVER_PLAN}/${serverPlanId}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setFetchServerPlanErrorMessage(result.data.message);
            return;
        }
        this.setServerPlan(result.data);
        this.setFetchServerPlanErrorMessage("");
    }

    @Action({ rawError: true })
    public async getStoragePlan(serverPlanId: number): Promise<void> {
        const response = await axios
            .get(`${GET_STORAGE_PLAN}/${serverPlanId}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setFetchServerPlanErrorMessage(result.data.message);
            return;
        }
        this.setStoragePlan(result.data);
        this.setFetchServerPlanErrorMessage("");
    }

    @Action({ rawError: true })
    public async postRegisterServerPlan(
        request: serverPlanForm
    ): Promise<void> {
        const response = await axios
            .post(POST_SERVER_PLAN_REGISTER, request.params, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }
        if (response.status === VALIDATION_ERROR) {
            this.setPostErrorMessage("入力エラー。入力値を確認してください");
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setPostErrorMessage(result.data.message);
            return;
        }
        this.setPostStatus(true);
        this.setPostErrorMessage("");
    }

    @Action({ rawError: true })
    public async postRegisterStoragePlan(
        request: storagePlanForm
    ): Promise<void> {
        const response = await axios
            .post(POST_STORAGE_PLAN_REGISTER, request.params, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }
        if (response.status === VALIDATION_ERROR) {
            this.setPostErrorMessage("入力エラー。入力値を確認してください");
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setPostErrorMessage(result.data.message);
            return;
        }
        this.setPostStatus(true);
        this.setPostErrorMessage("");
    }

    @Action({ rawError: true })
    public async putUpdateServerPlan(request: serverPlanForm): Promise<void> {
        const response = await axios
            .put(
                `${POST_SERVER_PLAN_UPDATE}/${request.serverPlanId}`,
                request.params,
                {
                    headers: {
                        Authorization: `Bearer ${authStore.token}`,
                    },
                }
            )
            .catch((error) => error.response || error);
        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }
        if (response.status === VALIDATION_ERROR) {
            this.setPostErrorMessage("入力エラー。入力値を確認してください");
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setPostErrorMessage(result.data.message);
            return;
        }
        this.setPostStatus(true);
        this.setPostErrorMessage("");
    }

    @Action({ rawError: true })
    public async putUpdateStoragePlan(request: storagePlanForm): Promise<void> {
        const response = await axios
            .put(
                `${POST_STORAGE_PLAN_UPDATE}/${request.storagePlanId}`,
                request.params,
                {
                    headers: {
                        Authorization: `Bearer ${authStore.token}`,
                    },
                }
            )
            .catch((error) => error.response || error);
        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }
        if (response.status === VALIDATION_ERROR) {
            this.setPostErrorMessage("入力エラー。入力値を確認してください");
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setPostErrorMessage(result.data.message);
            return;
        }
        this.setPostStatus(true);
        this.setPostErrorMessage("");
    }

    @Action({ rawError: true })
    public async deleteServerPlan(planId: number): Promise<void> {
        const response = await axios
            .delete(`${DELETE_SERVER_PLAN}/${planId}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            this.setDeleteStatus(false);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setDeleteErrorMessage(result.data.message);
            return;
        }
        this.setDeleteErrorMessage("");
        this.setDeleteStatus(true);
    }

    @Action({ rawError: true })
    public async deleteStoragePlan(planId: number): Promise<void> {
        const response = await axios
            .delete(`${DELETE_STORAGE_PLAN}/${planId}`, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            this.setDeleteStatus(false);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setDeleteErrorMessage(result.data.message);
            return;
        }
        this.setDeleteErrorMessage("");
        this.setDeleteStatus(true);
    }
}

export const providerPlanStore = getModule(ProviderPlanStore);
