














import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { authStore } from "@/models/store/AuthStore";

@Component
export default class HeaderComponent extends Vue {
    @Prop({ default: false }) private drawer!: boolean;

    @Emit()
    public clickNavIcon(): void {
        this.$emit("navigationFlag", !this.drawer);
    }

    private get isAuthenticated(): boolean {
        return authStore.isAuthenticated;
    }

    private async logout(): Promise<void> {
        await authStore.initLocalStorage();
        this.$router.push("/");
    }
}
