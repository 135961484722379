



















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ServiceAccountForm } from "@/types/modules/service-account";
import { FormTypes } from "@/types/modules/common";
import { serviceAccountStore } from "@/models/store/ServiceAccounts/ServiceAccountStore";

import FormTypeClass from "@/models/class/form-type";
import PageBackButton from "@/components/parts/PageBackButton.vue";

@Component({
    components: { PageBackButton },
})
export default class ServiceAccountFormComponent extends Vue {
    @Prop({ type: String, default: null }) readonly serviceId!: string | null;
    @Prop({ type: String, default: null }) readonly accountId!: string | null;
    @Prop({ type: String, default: "new" }) readonly formType!: FormTypes;

    private serviceAccountForm: ServiceAccountForm = {
        serviceName: "",
        url: "",
        loginId: "",
        loginPassword: "",
        basicAuthenticationId: "",
        basicAuthenticationPassword: "",
        managementDatabase: "",
        memo: "",
    };
    private errorMessage = "";
    private showLoginPassword = false;
    private showBasicAuthenticationPassword = false;
    private formTypeClass = new FormTypeClass("new");

    // フォームバリデーション
    valid = true;
    private rules = {
        serviceName: [
            (v: string) => !!v || "サービス名を入力してください",
            (v: string) =>
                v.length < 200 || "サービス名は200文字以内で入力してください",
        ],
        url: [(v: string) => !!v || "URLを入力してください"],
        loginId: [
            (v: string) =>
                v.length < 255 || "ログインIDは255文字以内で入力してください",
        ],
        basicAuthenticationId: [
            (v: string) =>
                v.length < 255 || "Basic認証IDは255文字以内で入力してください",
        ],
        managementDatabase: [
            (v: string) =>
                v.length < 100 || "管理DBは100文字以内で入力してください",
        ],
    };
    public get requiredLoginPasswordError(): string {
        return this.serviceAccountForm.loginId === "" ||
            this.serviceAccountForm.loginPassword
            ? ""
            : "ログインID入力時、ログインパスワードは必須入力です";
    }
    public get requiredBasicAuthenticationPasswordError(): string {
        return this.serviceAccountForm.basicAuthenticationId === "" ||
            this.serviceAccountForm.basicAuthenticationPassword
            ? ""
            : "Basic認証ID入力時、Basic認証パスワードは必須入力です";
    }

    public get requiredLoginInfoOrBasicAuthenticationInfoError(): string {
        return this.serviceAccountForm.loginId ||
            this.serviceAccountForm.basicAuthenticationId
            ? ""
            : "ログイン情報または、Basic認証情報のどちらかは必須入力です";
    }

    public created(): void {
        serviceAccountStore.initStore();

        this.formTypeClass = new FormTypeClass(this.formType);
        if (this.formTypeClass.isRegisterPage()) return;
        // パラメータでstring型でidが来るのでnumber型に変換
        const serviceId = Number(this.serviceId);
        const accountId = Number(this.accountId);
        this.updateFormSetting(serviceId, accountId);
    }

    private updateFormSetting(serviceId: number, accountId: number): void {
        this.fetchServiceAccountById(serviceId, accountId);
    }

    public get displayTitle(): string {
        return this.formTypeClass.isRegisterPage()
            ? "アカウント情報の登録"
            : "アカウント情報の更新";
    }

    public get displayButtonMessage(): string {
        return this.formTypeClass.isRegisterPage()
            ? "アカウントを追加"
            : "アカウントを更新";
    }

    /**
     * フォーム情報の登録・更新
     */
    private async submit(): Promise<void> {
        this.formTypeClass.isRegisterPage()
            ? await serviceAccountStore.postRegisterServiceAccount(
                  this.serviceAccountForm
              )
            : await serviceAccountStore.postUpdateServiceAccount({
                  serviceId: Number(this.serviceId),
                  accountId: Number(this.accountId),
                  serviceAccountForm: this.serviceAccountForm,
              });
        if (serviceAccountStore.postStatus) {
            this.$router.push("/service-account/list");
        }
    }

    /**
     * 各idから該当サービスアカウント情報を取得
     */
    private async fetchServiceAccountById(
        serviceIdentifier: number,
        accountIdentifier: number
    ): Promise<void> {
        const params = {
            serviceId: serviceIdentifier,
            accountId: accountIdentifier,
        };
        await serviceAccountStore.getServiceAccount(params);
        const serviceAccount = serviceAccountStore.serviceAccount;
        this.serviceAccountForm.serviceName = serviceAccount.serviceName;
        this.serviceAccountForm.url = serviceAccount.url;
        this.serviceAccountForm.loginId = serviceAccount.loginId ?? "";
        this.serviceAccountForm.loginPassword =
            serviceAccount.loginPassword ?? "";
        this.serviceAccountForm.basicAuthenticationId =
            serviceAccount.basicAuthenticationId ?? "";
        this.serviceAccountForm.basicAuthenticationPassword =
            serviceAccount.basicAuthenticationPassword ?? "";
        this.serviceAccountForm.managementDatabase =
            serviceAccount.managementDatabase ?? "";
        this.serviceAccountForm.memo = serviceAccount.memo ?? "";
    }
}
