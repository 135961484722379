import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { GroupInfo } from "@/types/api/permission";
import { GET_BELONGS_PERMISSION_GROUP_INDEX } from "@/configs/ApiConfig";
import { authStore } from "../AuthStore";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";

@Module({
    name: "belongsPermissionGroup",
    dynamic: true,
    store,
    namespaced: true,
})
class BelongsPermissionGroupStore extends VuexModule {
    private _canShowGroupList: GroupInfo[] = [];
    private _canAddGroupList: GroupInfo[] = [];
    private _canEditGroupList: GroupInfo[] = [];
    private _canDeleteGroupList: GroupInfo[] = [];

    private _errorMessage = "";

    public get canShowGroupList(): GroupInfo[] {
        return this._canShowGroupList;
    }

    public get canAddGroupList(): GroupInfo[] {
        return this._canAddGroupList;
    }

    public get canEditGroupList(): GroupInfo[] {
        return this._canEditGroupList;
    }

    public get canDeleteGroupList(): GroupInfo[] {
        return this._canDeleteGroupList;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    @Mutation
    private setCanShowGroupList(data: GroupInfo[]): void {
        this._canShowGroupList = data;
    }

    @Mutation
    private setCanAddGroupList(data: GroupInfo[]): void {
        this._canAddGroupList = data;
    }

    @Mutation
    private setCanEditGroupList(data: GroupInfo[]): void {
        this._canEditGroupList = data;
    }

    @Mutation
    private setCanDeleteGroupList(data: GroupInfo[]): void {
        this._canDeleteGroupList = data;
    }

    @Mutation
    private setErrorMessage(message: string): void {
        this._errorMessage = message;
    }

    @Action({ rawError: true })
    public refreshErrorMessage(): void {
        this.setErrorMessage("");
    }

    @Action({ rawError: true })
    public async getBelongsPermissionGroupIndex(): Promise<void> {
        const response = await axios
            .get(
                `${GET_BELONGS_PERMISSION_GROUP_INDEX}/${authStore.employeeId}`,
                {
                    headers: {
                        Authorization: `Bearer ${authStore.token}`,
                    },
                }
            )
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }
        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setErrorMessage(result.data.message);
            return;
        }

        this.setErrorMessage("");
        this.setCanShowGroupList(result.data.show);
        this.setCanAddGroupList(result.data.add);
        this.setCanEditGroupList(result.data.edit);
        this.setCanDeleteGroupList(result.data.delete);
    }
}

export const belongsPermissionGroupStore = getModule(
    BelongsPermissionGroupStore
);
