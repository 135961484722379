import { DataTableHeader } from "vuetify";

export const SERVER_INFO_HEADER: DataTableHeader[] = [
    {
        text: "種別①",
        align: "center",
        value: "largeCategory",
        width: "100",
        filterable: false,
    },
    {
        text: "種別②",
        align: "center",
        value: "smallCategory",
        width: "100",
        filterable: false,
    },
    {
        text: "システム名",
        align: "center",
        value: "system.systemName",
        width: "150",
    },
    {
        text: "サーバー名",
        align: "center",
        value: "name",
        width: "150",
        filterable: false,
    },
    {
        text: "踏み台",
        align: "center",
        value: "bastionHost",
        width: "100",
        filterable: false,
    },
    {
        text: "IPアドレス",
        align: "center",
        value: "ip",
        width: "200",
        filterable: false,
    },
    {
        text: "ドメイン",
        align: "center",
        value: "domain",
        width: "200",
        filterable: false,
    },
    {
        text: "SSHポート",
        align: "center",
        value: "ssh[0].sshPort",
        width: "120",
        filterable: false,
    },
    {
        text: "SSH鍵ファイル",
        align: "center",
        value: "ssh[0].sshKeyFilePath",
        width: "150",
        filterable: false,
    },
    {
        text: "SSHユーザー",
        align: "center",
        value: "ssh[0].sshUser",
        width: "150",
        filterable: false,
    },
    {
        text: "SSHパスワード",
        align: "center",
        value: "ssh[0].sshPassword",
        width: "150",
        filterable: false,
    },
    {
        text: "プロバイダ名",
        align: "center",
        value: "serverPlan.providerName",
        width: "150",
        filterable: false,
    },
    {
        text: "ゾーン",
        align: "center",
        value: "serverPlan.zone",
        width: "120",
        filterable: false,
    },
    {
        text: "インスタンス名",
        align: "center",
        value: "serverPlan.instanceName",
        width: "200px",
        filterable: false,
    },
    {
        text: "コア",
        align: "center",
        value: "serverPlan.core",
        width: "120px",
        filterable: false,
    },
    {
        text: "RAM(GB)",
        align: "center",
        value: "serverPlan.ram",
        width: "120px",
        filterable: false,
    },
    {
        text: "基本料金",
        align: "center",
        value: "serverPlan.baseFee",
        width: "200px",
        filterable: false,
    },
    {
        text: "ストレージ(GB)",
        align: "center",
        value: "storagePlan.capacity",
        width: "150",
        filterable: false,
    },
    {
        text: "ストレージ料金",
        align: "center",
        value: "storagePlan.fee",
        width: "150",
        filterable: false,
    },
    {
        text: "追加ストレージ1(GB)",
        align: "center",
        value: "extraStoragePlan[0].capacity",
        width: "170",
        filterable: false,
    },
    {
        text: "追加ストレージ2(GB)",
        align: "center",
        value: "extraStoragePlan[1].capacity",
        width: "170",
        filterable: false,
    },
    {
        text: "追加ストレージ3(GB)",
        align: "center",
        value: "extraStoragePlan[2].capacity",
        width: "170",
        filterable: false,
    },
    {
        text: "追加ストレージ料金",
        align: "center",
        value: "totalExtraStoragePlanFee",
        width: "160",
        filterable: false,
    },
    {
        text: "IPアドレス料金",
        align: "center",
        value: "serverPlan.ipAddressFee",
        width: "200",
        filterable: false,
    },
    {
        text: "想定NW料金",
        align: "center",
        value: "networkFee",
        width: "120",
        filterable: false,
    },
    {
        text: "料金小計",
        align: "center",
        value: "totalAmount",
        width: "120",
        filterable: false,
    },
    {
        text: "備考",
        align: "center",
        value: "memo",
        width: "150",
        filterable: false,
    },
    {
        text: "操作",
        align: "center",
        value: "action",
        width: "150px",
        filterable: false,
    },
];

export const PROXY_SERVER_INFO_HEADER: DataTableHeader[] = [
    {
        text: "種別①",
        align: "center",
        value: "largeCategory",
        width: "100",
        filterable: false,
    },
    {
        text: "種別②",
        align: "center",
        value: "smallCategory",
        width: "100",
        filterable: false,
    },
    {
        text: "システム名",
        align: "center",
        value: "system.systemName",
        width: "150",
    },
    {
        text: "サーバー名",
        align: "center",
        value: "name",
        width: "150",
        filterable: false,
    },
    {
        text: "踏み台",
        align: "center",
        value: "bastionHost",
        width: "100",
        filterable: false,
    },
    {
        text: "IPアドレス",
        align: "center",
        value: "ip",
        width: "200",
        filterable: false,
    },
    {
        text: "ドメイン",
        align: "center",
        value: "domain",
        width: "200",
        filterable: false,
    },
    {
        text: "SSHポート",
        align: "center",
        value: "ssh[0].sshPort",
        width: "120",
        filterable: false,
    },
    {
        text: "SSH鍵ファイル",
        align: "center",
        value: "ssh[0].sshKeyFilePath",
        width: "150",
        filterable: false,
    },
    {
        text: "SSHユーザー",
        align: "center",
        value: "ssh[0].sshUser",
        width: "150",
        filterable: false,
    },
    {
        text: "SSHパスワード",
        align: "center",
        value: "ssh[0].sshPassword",
        width: "150",
        filterable: false,
    },
    {
        text: "プロバイダ名",
        align: "center",
        value: "serverPlan.providerName",
        width: "150",
        filterable: false,
    },
    {
        text: "ゾーン",
        align: "center",
        value: "serverPlan.zone",
        width: "120",
        filterable: false,
    },
    {
        text: "インスタンス名",
        align: "center",
        value: "serverPlan.instanceName",
        width: "200px",
        filterable: false,
    },
    {
        text: "コア",
        align: "center",
        value: "serverPlan.core",
        width: "120px",
        filterable: false,
    },
    {
        text: "RAM(GB)",
        align: "center",
        value: "serverPlan.ram",
        width: "120px",
        filterable: false,
    },
    {
        text: "基本料金",
        align: "center",
        value: "serverPlan.baseFee",
        width: "200px",
        filterable: false,
    },
    {
        text: "ストレージ(GB)",
        align: "center",
        value: "storagePlan.capacity",
        width: "150",
        filterable: false,
    },
    {
        text: "ストレージ料金",
        align: "center",
        value: "storagePlan.fee",
        width: "150",
        filterable: false,
    },
    {
        text: "追加ストレージ1(GB)",
        align: "center",
        value: "extraStoragePlan[0].capacity",
        width: "170",
        filterable: false,
    },
    {
        text: "追加ストレージ2(GB)",
        align: "center",
        value: "extraStoragePlan[1].capacity",
        width: "170",
        filterable: false,
    },
    {
        text: "追加ストレージ3(GB)",
        align: "center",
        value: "extraStoragePlan[2].capacity",
        width: "170",
        filterable: false,
    },
    {
        text: "追加ストレージ料金",
        align: "center",
        value: "totalExtraStoragePlanFee",
        width: "160",
        filterable: false,
    },
    {
        text: "IPアドレス料金",
        align: "center",
        value: "serverPlan.ipAddressFee",
        width: "200px",
        filterable: false,
    },
    {
        text: "想定NW料金",
        align: "center",
        value: "networkFee",
        width: "120",
        filterable: false,
    },
    {
        text: "料金小計",
        align: "center",
        value: "totalAmount",
        width: "120",
        filterable: false,
    },
    {
        text: "備考",
        align: "center",
        value: "memo",
        width: "150",
        filterable: false,
    },
    {
        text: "squidバージョン",
        align: "center",
        value: "proxyInfo.squidVersion",
        width: "140",
        filterable: false,
    },
    {
        text: "squidポート",
        align: "center",
        value: "proxyInfo.squidPort",
        width: "120",
        filterable: false,
    },
    {
        text: "操作",
        align: "center",
        value: "action",
        width: "150px",
        filterable: false,
    },
];

export const WHITE_LIST_SERVER_INFO_HEADER: DataTableHeader[] = [
    {
        text: "種別①",
        align: "center",
        value: "largeCategory",
        width: "100",
        filterable: false,
    },
    {
        text: "種別②",
        align: "center",
        value: "smallCategory",
        width: "100",
        filterable: false,
    },
    {
        text: "システム名",
        align: "center",
        value: "system.systemName",
        width: "150",
    },
    {
        text: "サーバー名",
        align: "center",
        value: "name",
        width: "150",
        filterable: false,
    },
    {
        text: "踏み台",
        align: "center",
        value: "bastionHost",
        width: "100",
        filterable: false,
    },
    {
        text: "IPアドレス",
        align: "center",
        value: "ip",
        width: "200",
        filterable: false,
    },
    {
        text: "ドメイン",
        align: "center",
        value: "domain",
        width: "200",
        filterable: false,
    },
    {
        text: "SSHポート",
        align: "center",
        value: "ssh[0].sshPort",
        width: "120",
        filterable: false,
    },
    {
        text: "SSH鍵ファイル",
        align: "center",
        value: "ssh[0].sshKeyFilePath",
        width: "150",
        filterable: false,
    },
    {
        text: "SSHユーザー",
        align: "center",
        value: "ssh[0].sshUser",
        width: "150",
        filterable: false,
    },
    {
        text: "SSHパスワード",
        align: "center",
        value: "ssh[0].sshPassword",
        width: "150",
        filterable: false,
    },
    {
        text: "プロバイダ名",
        align: "center",
        value: "serverPlan.providerName",
        width: "150",
        filterable: false,
    },
    {
        text: "ゾーン",
        align: "center",
        value: "serverPlan.zone",
        width: "120",
        filterable: false,
    },
    {
        text: "インスタンス名",
        align: "center",
        value: "serverPlan.instanceName",
        width: "200px",
        filterable: false,
    },
    {
        text: "コア",
        align: "center",
        value: "serverPlan.core",
        width: "120px",
        filterable: false,
    },
    {
        text: "RAM(GB)",
        align: "center",
        value: "serverPlan.ram",
        width: "120px",
        filterable: false,
    },
    {
        text: "基本料金",
        align: "center",
        value: "serverPlan.baseFee",
        width: "200px",
        filterable: false,
    },
    {
        text: "ストレージ(GB)",
        align: "center",
        value: "storagePlan.capacity",
        width: "150",
        filterable: false,
    },
    {
        text: "ストレージ料金",
        align: "center",
        value: "storagePlan.fee",
        width: "150",
        filterable: false,
    },
    {
        text: "追加ストレージ1(GB)",
        align: "center",
        value: "extraStoragePlan[0].capacity",
        width: "170",
        filterable: false,
    },
    {
        text: "追加ストレージ2(GB)",
        align: "center",
        value: "extraStoragePlan[1].capacity",
        width: "170",
        filterable: false,
    },
    {
        text: "追加ストレージ3(GB)",
        align: "center",
        value: "providerPlan.storageInfo.extraStorages[2]",
        width: "170",
        filterable: false,
    },
    {
        text: "追加ストレージ料金",
        align: "center",
        value: "totalExtraStoragePlanFee",
        width: "160",
        filterable: false,
    },
    {
        text: "IPアドレス料金",
        align: "center",
        value: "serverPlan.ipAddressFee",
        width: "200px",
        filterable: false,
    },
    {
        text: "想定NW料金",
        align: "center",
        value: "networkFee",
        width: "120",
        filterable: false,
    },
    {
        text: "料金小計",
        align: "center",
        value: "totalAmount",
        width: "120",
        filterable: false,
    },
    {
        text: "備考",
        align: "center",
        value: "memo",
        width: "150",
        filterable: false,
    },
    {
        text: "コスト回線",
        align: "center",
        value: "whiteServerInfo.cost",
        width: "120",
        filterable: false,
    },
    {
        text: "アクリート",
        align: "center",
        value: "whiteServerInfo.accrete",
        width: "120",
        filterable: false,
    },
    {
        text: "操作",
        align: "center",
        value: "action",
        width: "150px",
        filterable: false,
    },
];

export const MEMBERSHIP_EMPLOYEES_HEADER: DataTableHeader[] = [
    {
        text: "従業員ID",
        align: "center",
        value: "employeeId",
        filterable: false,
    },
    {
        text: "従業員名",
        align: "center",
        value: "employeeName",
    },
    {
        text: "所属グループ名",
        align: "center",
        value: "group[0].groupName",
        filterable: false,
    },
];

export const NON_MEMBERSHIP_EMPLOYEES_HEADER: DataTableHeader[] = [
    {
        text: "従業員ID",
        align: "center",
        value: "employeeId",
        filterable: false,
    },
    {
        text: "氏名",
        align: "center",
        value: "employeeName",
    },
    {
        text: "所属グループ名",
        align: "center",
        value: "group[0].groupName",
        filterable: false,
    },
];
