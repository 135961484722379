




























































































































































































































import { Component, Vue } from "vue-property-decorator";
import { MembershipEmployee, PermissionGroup } from "@/types/api/permission";
import PageBackButton from "@/components/parts/PageBackButton.vue";
import {
    MEMBERSHIP_EMPLOYEES_HEADER,
    NON_MEMBERSHIP_EMPLOYEES_HEADER,
} from "@/libs/table-headers/Pemission.header";
import { DataTableHeader } from "vuetify";
import { OperationType, PermissionForm } from "@/types/modules/permission";

import { permissionGroupFormStore } from "@/models/store/Permissions/PermissionGroupFormStore";
import { groupPermissionUpdateStore } from "@/models/store/Permissions/GroupPermissionUpdateStore";

@Component({
    components: { PageBackButton },
})
export default class PermissionFormComponent extends Vue {
    private groupId = Number(this.$route.params.id);
    private groupPermission: PermissionGroup = {
        groupId: this.groupId,
        groupName: "",
        systemName: "",
        accountType: "管理者",
        members: [],
        permissionList: {
            show: false,
            add: false,
            edit: false,
            delete: false,
        },
    };

    private permissionForm: PermissionForm = {
        groupId: this.groupId,
        permissionList: {
            show: false,
            add: false,
            edit: false,
            delete: false,
        },
    };

    private membershipEmployeeHeader: DataTableHeader[] =
        MEMBERSHIP_EMPLOYEES_HEADER;
    private membershipEmployees: MembershipEmployee[] = [];
    private membershipEmployeeSelect: MembershipEmployee[] = [];
    private nonMembershipEmployeeHeader: DataTableHeader[] =
        NON_MEMBERSHIP_EMPLOYEES_HEADER;
    private nonMembershipEmployees: MembershipEmployee[] = [];
    private nonMembershipEmployeeSelect: MembershipEmployee[] = [];

    // 検索関連
    private nonMembershipEmployeeSearch = "";
    private membershipEmployeeSearch = "";

    public async created(): Promise<void> {
        groupPermissionUpdateStore.initMessage();
        await this.fetchPermissionGroupById();
        await this.fetchNonMembershipEmployees();
        this.permissionForm = {
            groupId: this.groupPermission.groupId,
            permissionList: {
                show: this.groupPermission.permissionList.show,
                add: this.groupPermission.permissionList.add,
                edit: this.groupPermission.permissionList.edit,
                delete: this.groupPermission.permissionList.delete,
            },
        };
    }

    /**
     * 該当グループ情報を取得APIにリクエスト
     */
    private async fetchPermissionGroupById(): Promise<void> {
        await permissionGroupFormStore.getPermissionGroupById(this.groupId);
        this.groupPermission = permissionGroupFormStore.groupPermissionForm;
        this.membershipEmployees = permissionGroupFormStore.membershipEmployees;
    }

    /**
     * 指定グループ未所属の従業員を取得
     */
    private async fetchNonMembershipEmployees(): Promise<void> {
        await permissionGroupFormStore.getNonMembershipEmployees(this.groupId);
        this.nonMembershipEmployees =
            permissionGroupFormStore.nonMembershipEmployees;
    }

    /**
     * グループ権限区分の更新
     */
    private async updateGroupPermission(): Promise<void> {
        await groupPermissionUpdateStore.postUpdateGroupPermission(
            this.permissionForm
        );
    }

    /**
     * グループメンバーの削除
     */
    private async removeEmployee(): Promise<void> {
        await groupPermissionUpdateStore.deleteMemberships({
            targetGroupId: this.groupId,
            employee: this.membershipEmployeeSelect,
        });
        await this.fetchPermissionGroupById();
        await this.fetchNonMembershipEmployees();
        this.membershipEmployeeSelect = [];
    }

    /**
     * グループメンバーの追加
     */
    private async addEmployee(): Promise<void> {
        await groupPermissionUpdateStore.registerMemberships({
            targetGroupId: this.groupId,
            employee: this.nonMembershipEmployeeSelect,
        });
        await this.fetchPermissionGroupById();
        await this.fetchNonMembershipEmployees();
        this.nonMembershipEmployeeSelect = [];
    }

    public get fetchErrorMessage(): string {
        return permissionGroupFormStore.fetchErrorMessage;
    }

    public get operationType(): OperationType {
        return groupPermissionUpdateStore.operationType;
    }

    public get successMessage(): string {
        return groupPermissionUpdateStore.successMessage;
    }

    public get errorMessage(): string {
        return groupPermissionUpdateStore.errorMessage;
    }
}
