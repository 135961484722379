


























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { FormTypes } from "@/types/modules/common";
import FormTypeClass from "@/models/class/form-type";
import { SshAccountForm } from "@/types/modules/server";
import PageBackButton from "@/components/parts/PageBackButton.vue";
import { accountTypeValues } from "@/types/modules/permission";
import { accountFormStore } from "@/models/store/Servers/AccountFormStore";
import { ServerInfo } from "@/types/api/server";
import { serverFormStore } from "@/models/store/Servers/ServerFormStore";

@Component({
    components: {
        PageBackButton,
    },
})
export default class ServerAccountFormComponent extends Vue {
    @Prop({ type: String, default: "new" }) readonly formType!: FormTypes;

    private accountTypeList = accountTypeValues;

    sshAccountFormList: SshAccountForm[] = [
        {
            accountType: this.accountTypeList[0],
            sshPort: 22,
            sshKeyFilePath: "",
            sshUser: "",
            sshPassword: "",
        },
    ];
    private showPassword = false;

    private formTypeClass = new FormTypeClass(this.formType);

    private valid = true;
    private rules = {
        accountType: [(v: string) => !!v || "アカウント区分を選択してください"],
        sshPort: [
            (v: number) => !!v || "sshポート番号を入力してください",
            (v: number) => v > 1 || "sshポートは1以上で入力してください",
            (v: number) =>
                v <= 65535 || "sshポートは65535以下で入力してください",
        ],
        sshUser: [(v: string) => !!v || "sshユーザーを入力してください"],
        sshPassword: [(v: string) => !!v || "sshパスワードを入力してください"],
    };

    public created(): void {
        this.formTypeClass = new FormTypeClass(this.formType);
        accountFormStore.refreshError();
        if (this.formTypeClass.isRegisterPage()) return;
        this.updateFormSetting();
    }

    private updateFormSetting(): void {
        if (this.server === null) return;
        if (!this.server.ssh) return;
        this.sshAccountFormList = this.server.ssh.map((sshInfo) => {
            return {
                accountType: sshInfo.accountType,
                sshPort: sshInfo.sshPort,
                sshKeyFilePath: sshInfo.sshKeyFilePath ?? "",
                sshUser: sshInfo.sshUser,
                sshPassword: sshInfo.sshPassword,
            };
        });
    }

    private async submit(): Promise<void> {
        accountFormStore.refreshError();
        const isRegisterPage = this.formTypeClass.isRegisterPage();
        if (isRegisterPage) {
            await accountFormStore.postAccountRegister(this.sshAccountFormList);
        } else {
            await accountFormStore.postAccountUpdate(this.sshAccountFormList);
        }

        if (accountFormStore.postStatus) {
            this.$router.push("/server/list");
        }
    }

    private addInput(): void {
        this.sshAccountFormList.push({
            accountType: this.accountTypeList[0],
            sshPort: 22,
            sshKeyFilePath: "",
            sshUser: "",
            sshPassword: "",
        });
    }

    private removeInput(index: number): void {
        this.sshAccountFormList.splice(index, 1);
    }

    public get displayTitle(): string {
        return this.formTypeClass.isRegisterPage()
            ? "サーバー情報登録"
            : "サーバー情報更新";
    }

    public get displayButtonMessage(): string {
        return this.formTypeClass.isRegisterPage()
            ? "SSHアカウント情報を登録"
            : "SSHアカウント情報を更新";
    }

    public get server(): ServerInfo | null {
        return serverFormStore.server;
    }

    public get postErrorMessage(): string {
        return accountFormStore.postErrorMessage;
    }
}
