import { DataTableHeader } from "vuetify";

export const PLATFORM_HEADER: DataTableHeader[] = [
    {
        text: "システム名",
        align: "center",
        value: "systemName",
        width: "200",
    },
    {
        text: "URL",
        align: "center",
        value: "url",
        width: "300",
        filterable: false,
    },
    {
        text: "id",
        align: "center",
        value: "loginId",
        width: "150",
        filterable: false,
    },
    {
        text: "パスワード",
        align: "center",
        value: "loginPassword",
        width: "150",
        filterable: false,
    },
    {
        text: "Basic認証id",
        align: "center",
        value: "basicAuthId",
        width: "150",
        filterable: false,
    },
    {
        text: "Basic認証パス",
        align: "center",
        value: "basicAuthPassword",
        width: "150",
        filterable: false,
    },
    {
        text: "管理DB",
        align: "center",
        value: "managementDatabase",
        width: "150",
        filterable: false,
    },
    {
        text: "備考",
        align: "center",
        value: "memo",
        width: "200",
        filterable: false,
    },
    {
        text: "操作",
        align: "center",
        value: "action",
        width: "120",
        filterable: false,
    },
];
