import { render, staticRenderFns } from "./ServicePermission.vue?vue&type=template&id=5b19ea6d&scoped=true&"
import script from "./ServicePermission.vue?vue&type=script&lang=ts&"
export * from "./ServicePermission.vue?vue&type=script&lang=ts&"
import style0 from "./ServicePermission.vue?vue&type=style&index=0&id=5b19ea6d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b19ea6d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VContainer,VRadio,VRadioGroup,VSelect})
