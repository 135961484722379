

















































































import { Component, Vue } from "vue-property-decorator";
import { SystemInfo } from "@/types/api/system";
import { PermissionGroupForm } from "@/types/modules/permission";
import { accountTypeValues } from "@/types/modules/permission";
import { systemStore } from "@/models/store/SystemStore";
import { permissionGroupFormStore } from "@/models/store/Permissions/PermissionGroupFormStore";

@Component
export default class AddGroupDialogComponent extends Vue {
    private accountTypeList = accountTypeValues;

    private permissionGroupForm: PermissionGroupForm = {
        groupName: "",
        systemName: "",
        accountType: this.accountTypeList[0],
        permissionList: {
            show: false,
            add: false,
            edit: false,
            delete: false,
        },
    };

    public created(): void {
        systemStore.getSystemList();
    }

    /**
     * フォームバリデーション
     */
    private valid = true;
    private rules = {
        groupName: [(v: string) => !!v || "グループ名を入力してください"],
        systemName: [
            (v: string) => !!v || "対象のシステム名を選択してください",
        ],
    };

    public get systemList(): SystemInfo[] {
        return systemStore.systemList;
    }

    /**
     * 権限グループの新規登録
     */
    private async registerPermissionGroup(): Promise<void> {
        await permissionGroupFormStore.postRegisterPermissionGroup(
            this.permissionGroupForm
        );
        if (permissionGroupFormStore.postStatus) {
            this.closeDialog();
        }
    }

    public get errorMessage(): string {
        return permissionGroupFormStore.postErrorMessage;
    }

    /**
     * フォームの初期化
     */
    private initForm(): void {
        this.permissionGroupForm.groupName = "";
        this.permissionGroupForm.systemName = "";
        this.permissionGroupForm.accountType = this.accountTypeList[0];
    }

    /**
     * ダイアログを閉じる
     */
    private closeDialog(): void {
        this.initForm();
        this.$emit("input", false);
    }
}
