






import { Component, Vue } from "vue-property-decorator";

@Component
export default class PageBackButtonComponent extends Vue {
    private pageBack(): void {
        this.$router.back();
    }
}
