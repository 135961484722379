import { AccountType } from "./permission";

export interface ServerForm {
    largeCategory: string;
    smallCategory: string;
    system: string;
    name: string;
    ip: string;
    domains: string[];
    providerName: string;
    serverPlanId: number;
    storagePlanId: number;
    hasExtraStorage: boolean;
    extraStoragePlanIdList: {
        extraStoragePlan1: number;
        extraStoragePlan2: number;
        extraStoragePlan3: number;
    };
    bastionHost: boolean;
    networkFee: number;
    memo: string;
    contractStatus: ContractStatus;
    proxy: boolean;
    squidVersion: string;
    squidPort: number;
    whiteListServer: boolean;
    costLine: boolean;
    accrete: boolean;
}

export interface ServerIdInfo {
    serverId: number;
    sshAccountId: number;
}

export interface SshAccountForm {
    accountType: AccountType;
    sshKeyFilePath: string;
    sshPort: number;
    sshUser: string;
    sshPassword: string;
}

export interface CategoryForm {
    categoryType: CategoryTypes;
    categoryName: string;
}

export interface SystemForm {
    systemName: string;
}

export type CategoryTypes = "large" | "small";

export const contractStatusValues = ["契約中", "解約済み"] as const;
export type ContractStatusTuple = typeof contractStatusValues;
export type ContractStatus = ContractStatusTuple[number];
