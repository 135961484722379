































import { Component, Vue } from "vue-property-decorator";
import { ProviderForm } from "@/types/modules/provider-plan";
import { providerStore } from "@/models/store/Providers/ProviderStore";

@Component
export default class AddProviderDialogComponent extends Vue {
    private providerForm: ProviderForm = {
        providerName: "",
    };

    /**
     * フォームバリデーション
     */
    private valid = true;
    private rules = {
        providerName: [
            (v: string) => !!v || "プロバイダ名を入力してください",
            (v: string) =>
                v.length <= 100 ||
                "プロバイダ名は100文字以内で入力してください",
        ],
    };

    public created(): void {
        providerStore.initError();
    }

    /**
     * プロバイダ情報を新規登録
     */
    private async submit(): Promise<void> {
        await providerStore.postProviderRegister(this.providerForm);
        if (!this.hasError) {
            this.closeDialog();
        }
    }

    public get hasError(): boolean {
        return providerStore.hasPostError;
    }

    public get errorMessage(): string {
        return providerStore.postError;
    }

    /**
     * ダイアログを閉じる
     */
    private closeDialog(): void {
        this.$emit("input", false);
    }
}
