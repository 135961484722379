import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { ServerInfo } from "@/types/api/server";
import {
    DELETE_SERVER_AND_ACCOUNT,
    GET_SERVER_LIST,
} from "@/configs/ApiConfig";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED_ERROR } from "@/libs/util";
import { authStore } from "../AuthStore";
import { ServerIdInfo } from "@/types/modules/server";
import { belongsPermissionGroupStore } from "../Employees/BelongsPermissionGroupStore";

@Module({
    name: "serverList",
    dynamic: true,
    store,
    namespaced: true,
})
class ServerListStore extends VuexModule {
    private _serverList: ServerInfo[] = [];

    private _fetchErrorMessage = "";
    private _deleteErrorMessage = "";

    private _deleteStatus = false;

    public get serverList(): ServerInfo[] {
        return this._serverList;
    }

    public get fetchErrorMessage(): string {
        return this._fetchErrorMessage;
    }

    public get deleteErrorMessage(): string {
        return this._deleteErrorMessage;
    }

    public get deleteStatus(): boolean {
        return this._deleteStatus;
    }

    @Mutation
    private setServerList(data: ServerInfo[]): void {
        const filterServerList = data.filter((server) => {
            let matchFlag = false;
            belongsPermissionGroupStore.canShowGroupList.forEach(
                (showGroup) => {
                    matchFlag =
                        showGroup.systemName === server.system.systemName &&
                        (showGroup.accountType === "管理者" ||
                            server.ssh === undefined ||
                            showGroup.accountType ===
                                server.ssh[0].accountType);
                }
            );
            // システムの管理ユーザー or 権限グループ所属の場合は閲覧可能
            if (authStore.employeeType === "管理ユーザー" || matchFlag) {
                return server;
            }
        });
        this._serverList = filterServerList;
    }

    @Mutation
    private setFetchErrorMessage(message: string): void {
        this._fetchErrorMessage = message;
    }

    @Mutation
    private setDeleteErrorMessage(message: string): void {
        this._deleteErrorMessage = message;
    }

    @Mutation
    private setDeleteStatus(status: boolean): void {
        this._deleteStatus = status;
    }

    @Action({ rawError: true })
    public RefreshError(): void {
        this.setFetchErrorMessage("");
        this.setDeleteErrorMessage("");
        this.setDeleteStatus(false);
    }

    @Action({ rawError: true })
    public async getServerList(): Promise<void> {
        const response = await axios
            .get(GET_SERVER_LIST, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setFetchErrorMessage(result.data.message);
            return;
        }
        this.setFetchErrorMessage("");
        this.setServerList(result.data);
    }

    @Action({ rawError: true })
    public async postServerAndAccountDelete(
        idParams: ServerIdInfo
    ): Promise<void> {
        const url =
            idParams.sshAccountId === 0
                ? `${DELETE_SERVER_AND_ACCOUNT}/${idParams.serverId}/`
                : `${DELETE_SERVER_AND_ACCOUNT}/${idParams.serverId}/${idParams.sshAccountId}`;
        const response = await axios
            .delete(url, {
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
            })
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        if (response.status === UNAUTHORIZED_ERROR) {
            authStore.setAuthError(response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setDeleteErrorMessage(result.data.message);
            return;
        }
        this.setDeleteErrorMessage("");
        this.setDeleteStatus(true);
    }
}

export const serverListStore = getModule(ServerListStore);
