

































import { Component, Prop, Vue } from "vue-property-decorator";
import { CategoryForm, CategoryTypes } from "@/types/modules/server";
import { categoryStore } from "@/models/store/Servers/CategoryStore";

@Component
export default class AddCategoryDialogComponent extends Vue {
    @Prop({ type: String, default: "large" })
    private readonly categoryType!: CategoryTypes;
    private categoryForm: CategoryForm = {
        categoryType: "large",
        categoryName: "",
    };

    /**
     * バリデーション
     */
    private valid = true;
    private required = [(v: string) => !!v || "カテゴリ名を入力してください"];

    public get displayDialogTitle(): string {
        return this.categoryType === "large"
            ? "大カテゴリの登録"
            : "小カテゴリの登録";
    }

    public created(): void {
        this.categoryForm.categoryType = this.categoryType;
    }

    /**
     * カテゴリ作成APIにリクエスト
     */
    private async postRegisterCategory(): Promise<void> {
        const result = await categoryStore.postRegisterCategory(
            this.categoryForm
        );
        if (result) {
            this.closeDialog();
        }
    }

    public get errorMessage(): string {
        return categoryStore.errorMessage;
    }

    public closeDialog(): void {
        this.categoryForm.categoryName = "";
        categoryStore.initError();
        this.$emit("input", false);
    }
}
