import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "@/models/store/index";
import axios from "axios";
import { errorStore } from "@/models/store/ErrorStore";
import { EmployeeAuthInfo } from "@/types/api/auth";
import { POST_LOGIN } from "@/configs/ApiConfig";
import { EmployeeTypes, LoginInfo } from "@/types/modules/auth-type";
import { INTERNAL_SERVER_ERROR } from "@/libs/util";

@Module({
    name: "auth",
    dynamic: true,
    store,
    namespaced: true,
})
class AuthStore extends VuexModule {
    private _employeeId = 0;
    private _employeeName = "";
    private _profileImageUrl = "";
    private _loginId = "";
    private _employeeType: EmployeeTypes = "通常ユーザー";
    private _token = "";
    private _expiresIn = "";

    private _isAuthenticated = false;
    private _authErrorMessage = "";

    public get employeeId(): number {
        return this._employeeId;
    }

    public get employeeName(): string {
        return this._employeeName;
    }

    public get profileImageUrl(): string {
        return this._profileImageUrl;
    }

    public get loginId(): string {
        return this._loginId;
    }

    public get employeeType(): EmployeeTypes {
        return this._employeeType;
    }

    public get token(): string {
        return this._token;
    }

    public get expiresIn(): string {
        return this._expiresIn;
    }

    public get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }

    public get authErrorMessage(): string {
        return this._authErrorMessage;
    }

    @Mutation
    private setEmployeeId(employeeId: number): void {
        if (employeeId !== 0) {
            localStorage.setItem("employeeId", employeeId.toString());
        } else {
            localStorage.removeItem("employeeId");
        }
        this._employeeId = employeeId;
    }

    @Mutation
    private setEmployeeName(employeeName: string): void {
        if (employeeName !== "") {
            localStorage.setItem("employeeName", employeeName);
        } else {
            localStorage.removeItem("employeeName");
        }
        this._employeeName = employeeName;
    }

    @Mutation
    private setProfileImageUrl(profileImageUrl: string): void {
        if (profileImageUrl !== "") {
            localStorage.setItem("profileImageUrl", profileImageUrl);
        } else {
            localStorage.removeItem("profileImageUrl");
        }
        this._profileImageUrl = profileImageUrl;
    }

    @Mutation
    private setLoginId(loginId: string): void {
        if (loginId !== "") {
            localStorage.setItem("loginId", loginId);
        } else {
            localStorage.removeItem("loginId");
        }
        this._loginId = loginId;
    }

    @Mutation
    private setEmployeeType(type: EmployeeTypes): void {
        localStorage.setItem("employeeType", type);
        this._employeeType = type;
    }

    @Mutation
    private setToken(token: string): void {
        if (token !== "") {
            localStorage.setItem("token", token);
        } else {
            localStorage.removeItem("token");
        }
        this._token = token;
    }

    @Mutation
    private setExpiresIn(expiresIn: string): void {
        if (expiresIn !== "") {
            localStorage.setItem("expiresIn", expiresIn);
        } else {
            localStorage.removeItem("expiresIn");
        }
        this._expiresIn = expiresIn;
    }

    @Mutation
    private setIsAuthenticated(status: boolean): void {
        this._isAuthenticated = status;
    }

    @Mutation
    private setAuthErrorMessage(message: string): void {
        this._authErrorMessage = message;
    }

    @Action({ rawError: true })
    private setAuthInfo(data: EmployeeAuthInfo): void {
        this.setEmployeeId(data.employeeId);
        this.setEmployeeName(data.employeeName);
        this.setProfileImageUrl(data.profileImageUrl);
        this.setLoginId(data.loginId);
        this.setEmployeeType(data.employeeType);
        this.setToken(data.tokenInfo.token);
        this.setExpiresIn(data.tokenInfo.expiresIn);
    }

    @Action({ rawError: true })
    public setAuthError(message: string): void {
        this.setIsAuthenticated(false);
        this.setAuthErrorMessage(message);
    }

    @Action({ rawError: true })
    public async login(loginInfo: LoginInfo): Promise<void> {
        const response = await axios
            .post(POST_LOGIN, loginInfo)
            .catch((error) => error.response || error);

        if (response.status === INTERNAL_SERVER_ERROR) {
            errorStore.setErrorInfo(response.status, response.data.message);
            return;
        }

        const result = response.data;
        if (result.status === "error") {
            this.setIsAuthenticated(false);
            this.setAuthErrorMessage(result.data.message);
            return;
        }

        if (result.status === "success") {
            this.setAuthInfo(result.data);
            this.setIsAuthenticated(true);
            this.setAuthErrorMessage("");
            errorStore.setErrorInfo(0, "");
        }
    }

    @Action({ rawError: true })
    public async initLocalStorage(): Promise<void> {
        this.setEmployeeId(0);
        this.setEmployeeName("");
        this.setProfileImageUrl("");
        this.setLoginId("");
        this.setToken("");
        this.setExpiresIn("");
        this.setIsAuthenticated(false);
    }
}

export const authStore = getModule(AuthStore);
