









































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { FormTypes } from "@/types/Common/common-type";
import { platformStore } from "@/models/store/Platform/PlatformStore";
import { PlatformForm } from "@/types/Platform/modules/platform-type";
import FormType from "@/models/Domain/Common/FormType";
import PageBackButton from "@/components/parts/PageBackButton.vue";
import { Platform } from "@/types/Platform/api/platform";

@Component({
    components: { PageBackButton },
})
export default class PlatformFormComponent extends Vue {
    @Prop({ type: String, default: "new" }) readonly type!: FormTypes;
    private showLoginPassword = false;
    private showBasicAuthenticationPassword = false;
    private form: PlatformForm = {
        systemName: "",
        url: "",
        loginId: "",
        loginPassword: "",
        basicAuthId: "",
        basicAuthPassword: "",
        managementDatabase: "",
        memo: "",
    };

    private formType = new FormType("new");
    /** フォームバリデーション関連 */
    private valid = true;
    private rules = {
        systemName: [
            (v: string) => !!v || "システム名を入力してください",
            (v: string) =>
                v.length < 200 || "サービス名は200文字以内で入力してください",
        ],
        url: [
            (v: string) => !!v || "URLを入力してください",
            (v: string) => /https?:/.test(v) || "正しい形式で入力してください",
        ],
        loginId: [
            (v: string) =>
                v.length < 255 || "ログインidは255文字以内で入力してください",
        ],
        basicAuthenticationId: [
            (v: string) =>
                v.length < 255 || "Basic認証idは255文字以内で入力してください",
        ],
        managementDatabase: [
            (v: string) =>
                v.length < 100 ||
                "管理データベースは100文字以内で入力してください",
        ],
    };

    public get requiredLoginPasswordError(): string {
        return this.form.loginId === "" || this.form.loginPassword
            ? ""
            : "ログインID入力時、ログインパスワードは必須入力です";
    }
    public get requiredBasicAuthPasswordError(): string {
        return this.form.basicAuthId === "" || this.form.basicAuthPassword
            ? ""
            : "Basic認証ID入力時、Basic認証パスワードは必須入力です";
    }

    public get requiredLoginInfoOrBasicAuthInfoError(): string {
        return this.form.loginId || this.form.basicAuthId
            ? ""
            : "ログイン情報または、Basic認証情報のどちらかは必須入力です";
    }

    public created(): void {
        platformStore.initStore();

        this.formType = new FormType(this.type);
        if (this.formType.isRegisterPage()) return;
        this.updateFormSetting();
    }

    private async updateFormSetting(): Promise<void> {
        await platformStore.getPlatform();
        if (this.platform === null) return;
        this.form.systemName = this.platform.systemName;
        this.form.url = this.platform.url;
        this.form.managementDatabase = this.platform.managementDatabase;
        this.form.loginId = this.platform.loginId;
        this.form.loginPassword = this.platform.loginPassword;
        this.form.basicAuthId = this.platform.basicAuthId;
        this.form.basicAuthPassword = this.platform.basicAuthPassword;
        this.form.memo = this.platform.memo;
    }

    public async submit(): Promise<void> {
        if (this.formType.isRegisterPage()) {
            await platformStore.registerPlatform(this.form);
        } else {
            await platformStore.updatePlatform(this.form);
        }

        if (!platformStore.hasError) {
            this.$router.push("/platform/list");
        }
    }

    public get displayTitle(): string {
        return this.formType.isRegisterPage()
            ? "プラットフォーム情報の登録"
            : "プラットフォーム情報の更新";
    }

    public get displayButtonMessage(): string {
        return this.formType.isRegisterPage()
            ? "プラットフォーム登録"
            : "プラットフォーム更新";
    }

    public get platform(): Platform | null {
        return platformStore.platform;
    }

    public get hasError(): boolean {
        return platformStore.hasError;
    }

    public get errorMessage(): string {
        return platformStore.errorMessage;
    }
}
