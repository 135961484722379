// 各テーブルの設定
import { DataTableHeader } from "vuetify";

export const MEMBERSHIP_EMPLOYEES_HEADER: DataTableHeader[] = [
    {
        text: "従業員ID",
        align: "center",
        value: "employeeId",
        filterable: false,
    },
    {
        text: "従業員名",
        align: "center",
        value: "employeeName",
    },
    {
        text: "所属グループ名",
        align: "center",
        value: "group[0].groupName",
        filterable: false,
    },
];

export const NON_MEMBERSHIP_EMPLOYEES_HEADER: DataTableHeader[] = [
    {
        text: "従業員ID",
        align: "center",
        value: "employeeId",
        filterable: false,
    },
    {
        text: "氏名",
        align: "center",
        value: "employeeName",
    },
    {
        text: "所属グループ名",
        align: "center",
        value: "group[0].groupName",
        filterable: false,
    },
];
