














































































































































































import { Component, Vue } from "vue-property-decorator";
import { ServiceAccountInfo } from "@/types/api/service-account";
import { DataTableHeader } from "vuetify";
import { SERVICE_ACCOUNT_HEADER } from "@/libs/table-headers/ServiceAccount.header";
import { serviceAccountStore } from "@/models/store/ServiceAccounts/ServiceAccountStore";

import PageBackButton from "@/components/parts/PageBackButton.vue";

@Component({
    components: {
        PageBackButton,
    },
})
export default class ServiceAccountListComponent extends Vue {
    private headers: DataTableHeader[] = SERVICE_ACCOUNT_HEADER;
    private search = "";
    private deleteDialog = false;
    private deleteServiceId = 0;
    private deleteAccountId = 0;

    public created(): void {
        serviceAccountStore.initStore();
        serviceAccountStore.getServiceAccountList();
    }

    public get serviceAccountList(): ServiceAccountInfo[] {
        return serviceAccountStore.serviceAccountList;
    }

    private editServiceAccount(service_id: number, account_id: number): void {
        // String型でないとパラメータ箇所でエラーが出る為、idをnumber型→string型に変換
        const serviceId = String(service_id);
        const accountId = String(account_id);
        this.$router.push({
            name: "ServiceAccountForm",
            params: {
                serviceId: serviceId,
                accountId: accountId,
                formType: "update",
            },
        });
    }

    private openDeleteConfirmDialog(
        serviceId: number,
        accountId: number
    ): void {
        this.deleteDialog = true;
        this.deleteServiceId = serviceId;
        this.deleteAccountId = accountId;
    }

    private closeDialog(): void {
        this.deleteDialog = false;
        this.deleteServiceId = 0;
        this.deleteAccountId = 0;
    }

    private async deleteServiceAccount(): Promise<void> {
        const params = {
            serviceId: this.deleteServiceId,
            accountId: this.deleteAccountId,
        };
        await serviceAccountStore.deleteServiceAccount(params);
        if (serviceAccountStore.deleteStatus) {
            this.deleteDialog = false;
            serviceAccountStore.getServiceAccountList();
        }
    }

    private writeToClipboard(text: string): void {
        navigator.clipboard.writeText(text).catch((err) => console.error(err));
    }

    public get errorMessage(): string {
        return serviceAccountStore.errorMessage;
    }

    public get deleteErrorMessage(): string {
        return serviceAccountStore.deleteErrorMessage;
    }
}
